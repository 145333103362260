.bp__control {
    min-width: 200px;
}

.bp__option {
    font-size: 70% !important;
}

.bp__single-value {
    font-size: 80%;
}

.bp__value-container {
    position: relative;
    top: -10px;
}


.searchInput .bp__control {
    padding: 0;
}

.searchInput .bp__control input {
    height: 24px !important;
}


.css-w8afj7-Input {
    padding: 0!important;
    margin: 0!important;
}
