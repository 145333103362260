.quotation-grandtotal {
}

.quotation-grandtotal .tit {
    padding-top: 1.5rem !important;
    padding-left: 1rem;
}

.quotation-grandtotal .column {
    border: solid 1px #f0f0f0;
}

.quotation-grandtotal .head .column {
    background-color: #353535 !important;
    color: #ffffff;
    text-align: center;
}

.quotation-grandtotal .amount .column {
    background-color: #dedede !important;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.quotation-grandtotal .bpcommission .column {
    background-color: #ababab !important;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.quotation-grandtotal .total .column {
    background-color: #434343 !important;
    color: #ffffff;
    font-weight: bold;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

