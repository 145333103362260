.quotation-item-form table
{
    border-collapse: collapse;
    border: none !important;
}
.quotation-item-form table td {
    background-color: #BAB1D1 !important;
    box-shadow: 0 0 1px #FFFFFF !important;
}

.quotation-item-form table th {
    background-color: #9684C6 !important;
    box-shadow: 0 0 1px #FFFFFF !important;
}

.quotation-item-form table tfoot td {
    background-color: #9684C6 !important;
}
