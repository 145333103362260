.centerImage {
  margin-left: auto;
  margin-right: auto;
}
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-weight: inherit;
}
.tagLive {
  font-size: 0.5rem !important;
}

.card-boxes {
  background: #fff;
  border-radius: 20px;
}

.cardMatch {
  font-size: 10px;
  height: 100px;
}

.hasTableCounter {
  counter-reset: serial-number;
}

.hasTableCounter td:first-child:before {
  counter-increment: serial-number;
  content: counter(serial-number);
  min-width: 1em;
  margin-right: 1em;
}

.is-active .modal-content {
  overflow: initial !important;
}

.paymentWarn {
  font-size: 10px;
}

.customAssetHeader {
  padding: 0.3rem 15px;
  font-weight: 600;
  color: #fff;
  background-color: #000;
}

.complexBadgeTxt {
  margin-left: 10px;
}

.complexBadge {
  position: absolute;
  background: #353535;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.iconNotificationWithLink {
  cursor: pointer;
}

.iconNotificationWithOutLink {
  cursor: default;
}

.notificationViewAll a {
  font-size: 12px;
  font-weight: bold;
  color: black;
}

.notificationItemTopicSeen {
  font-size: 13px;
  color: black;
}

.notificationItemTopicNotSeen {
  font-weight: bold;
  font-size: 14px;
  color: black;
}

.notificationItemTopicNotSeen .button {
  margin-right: 20px;
}

.notificationItemTopicDate {
  font-size: 10px;
  color: black;
}

.notificationItemDescription {
  padding-top: 100px;
}

.paddingButtonsCheckout {
  padding-top: 20px;
}

.caseDisplay {
  max-width: 600px;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

.card2 {
  box-shadow: 0 !important;
}

.termsContainer {
  height: 350px;
  width: 100%;
  padding: 3px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
}
.termsContent {
  height: 324px;
  overflow: auto;
  background: #fff;
  text-align: left;
  font-size: 14px;
}

.fileList {
  padding-left: 100px;
  font-size: 14px;
}

.smallCardInfo {
  font-size: 12px;
}

.not-active {
  cursor: default;
  text-decoration: none;
  color: black;
}

.previewInfo {
  margin-left: 60px;
  font-size: 14px;
}

.discount .has-text-grey-dark span {
  font-size: 10px;
  font-weight: lighter !important;
}

[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1.8em;
  font-family: 'Montserrat';
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: gray;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
}

.orSpace {
  margin-right: 30px;
}

.centerCard {
  margin-left: auto;
  margin-right: auto;
}

.discount {
  font-size: 10px;
  font-weight: lighter;
}

.bellNotificationYes,
.bellNotificationNo {
  color: #fff;
  font-size: 18px;
  position: relative;
  padding: 10px 0;
  margin-top: 2px;
}

.bellNotificationYes::after {
  position: absolute;
  content: '';
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #e30404;
  top: 12px;
  right: -2px;
}

.bellNotificationNo {
  color: #666666;
  font-size: 18px;
}

.partner-signup h1 {
  font-weight: bold;
}

.partner-signup p {
  margin-bottom: 1rem;
}

.field_half {
  width: 50%;
}

.logo img {
  width: 150px;
  margin-top: 10px;
}

.client-step-1 {
  background-color: #fafafa;
}

.client-step-2 {
  background-color: #eeeeee;
}

.client-step-3 {
  background-color: #eeeeee;
}

.client-step-4 {
  background-color: #eeeeee;
}

.client-step-5 {
  background-color: #eeeeee;
}

.client-step-6 {
  background-color: #eeeeee;
}

.partner-step-1 {
  background-color: #bce6f4;
  padding-top: 40px;
}

.partner-step-2 {
  background-color: #bdeedf;
  padding-top: 40px;
}

.partner-step-3 {
  background-color: #ffdbd3;
  padding-top: 40px;
}

.partner-step-4 {
  background-color: #f6e39f;
  padding-top: 40px;
}

.partner-step-5 {
  background-color: #bfb3d2;
  padding-top: 40px;
}

.partner-step-6 {
  background-color: #e2fbb9;
  padding-top: 40px;
}

.reference-step-1 {
  background-color: #bfb3d2;
}

.reference-step-2 {
  background-color: #bfb3d2;
}

.leader-step-1 {
  background-color: #f0e2a1;
}

.leader-step-2 {
  background-color: #ffc5b9;
}

.leader-step-3 {
  background-color: #a8d7eb;
}

.navbar-item img {
  max-height: 2.7rem;
}

.navbar.is-main {
  background-color: #007aff;
  padding: 3px 0;
}

.hero.signup {
}

.hero-head {
  background-color: #1c1c1c;
  position: fixed;
  width: 100%;
  z-index: 92;
}

.hero-body {
  align-items: unset !important;
  padding: 1.5rem 0 !important;
}

.slogan {
  color: #767676;
  padding-left: 30%;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.form-field-valid {
  color: #007800 !important;
}

.form-field-wrapper {
  margin-right: 0.75rem;
}

.is-disable {
  pointer-events: none;
  opacity: 0.65;
}

/*
 * PARTNER SIGNUP STEPS
 * --------------------------------------- */

#partner-signup-steps {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 12px;
  width: 100%;
}

#partner-signup-steps li {
  display: inline-block;
  width: calc(100% / 6);
  padding-right: 30px;
  font-size: 12px;
}

#partner-signup-steps li a {
  color: inherit;
  display: block;
  margin-left: 20px;
  border-top-style: solid;
  margin-top: -14px;
  padding-top: 12px;
  position: relative;
  font-size: 12px;
}

.progress-bar--item h3 {
  height: 30px;
}

.progress-bar--menu-item-mod {
  transform: translateY(0);
}

/*
 * NEW PROJECT STEPS
 * --------------------------------------- */

.hero-head.is-fixed + div > .hero-body.for-np {
  box-shadow: 1px 0 1px rgba(0, 0, 0, 0.15);
  padding-bottom: 8px !important;
  z-index: 20;
}

.hero-head.is-fixed + div > .hero-body.for-np .title {
  font-weight: 600;
  transform: translateY(2px);
}

#partner-signup-steps.partner-signup-newproj-steps h3 {
  font-size: 14px;
}

#partner-signup-steps.partner-signup-newproj-steps h3::after {
  content: '.';
}

#partner-signup-steps.partner-signup-newproj-steps li {
  /* padding-right: 0; */
}

#partner-signup-steps.partner-signup-newproj-steps li a {
  display: block;
  height: 100%;
  font-size: 11px;
  margin-top: -21px;
  width: 90%;
}

#partner-signup-steps.partner-signup-newproj-steps
  li
  a.progress-bar--menu-item {
  padding-bottom: 12px;
  padding-top: 0;
}

#partner-signup-steps.partner-signup-newproj-steps
  a.progress-bar--menu-item
  > span {
  opacity: 0;
}

#partner-signup-steps.partner-signup-newproj-steps
  .is-active
  a.progress-bar--menu-item
  > span {
  opacity: 1;
  background: #434343;
}

#partner-signup-steps.partner-signup-newproj-steps
  a.progress-bar--menu-item
  > span,
#partner-signup-steps.partner-signup-newproj-steps
  .is-active
  a.progress-bar--menu-item
  > span {
  bottom: 0;
  top: auto;
}

.np-buttons .button {
  display: block;
  margin: 0;
  position: relative;
}

.np-buttons .button.is-selected {
  border-color: black;
}

.np-buttons .control:first-child,
.np-buttons .control:last-child {
  width: 50%;
}

.np-buttons .button img {
  display: inline-block;
  left: 50%;
  height: 100px;
  margin: -9px 0 0 -84px;
  position: absolute;
  width: 42px;
}

.button.comunicacao img {
  left: 40%;
}

.np-buttons .button span {
  color: black;
  display: inline-block;
  font-size: 80%;
  line-height: 84px;
  text-indent: 42px;
  width: 100%;
}

.np-basicFormInit .field {
  width: 75%;
}

.title-for-np {
  font-size: 25px;
  font-weight: 300;
}

.title-for-np-bordered {
  padding-bottom: 12px;
}

.small-control-text {
  font-size: 75%;
  line-height: 1.25;
  padding: 12px 0 36px;
}

.regular-control-text {
  font-size: 85%;
  line-height: 1.6;
}

.for-np + div .container {
  padding-bottom: 64px;
}

.columns-for-np hr {
  height: 1px;
  margin: 2.5rem 0;
}

.left-column-for-np h4 {
  font-size: 85%;
  font-weight: 600;
}

.left-column-for-np h4.is-bigger {
  font-size: 25px;
  font-weight: 300;
}

.left-column-for-np .centered-button {
  text-align: center;
}

.left-column-for-np .button.is-black {
  font-weight: 600;
}

.for-np + div .checkbox {
  margin-bottom: 16px;
}

.for-np + div .checkbox input ~ .checkmark {
  background-color: #fff;
}

.for-np + div .checkbox input ~ .checkmark + p,
.left-column-for-np .is-pulled-right {
  font-size: 12px;
  line-height: 25px;
}

.right-column-for-np nav.level {
  margin-bottom: 0;
}

.right-column-for-np nav.level {
  margin-bottom: 0;
  margin-top: -10px;
}

.right-column-for-np .level-number h5 {
  color: #333;
  font-weight: 600;
}

.right-column-for-np nav.level + hr {
  margin-top: 16px;
}

.listBundle {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: row wrap;
}

.listBundle .tabs {
  padding: 0 30px;
}

.media-template-card {
  position: relative;
  border-radius: 20px;
  width: calc(50% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 20px !important;
  display: block !important;
}

.media-template-card.full {
  width: 100%;
  margin: 0;
}

.media-template-card .media-left {
  background-image: url('/img/ilustra.svg');
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 25%;
}

.media-template-card .subtitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0 !important;
  padding: 32px 32px 24px;
  font-family: 'Helvetica Neue LT Std';
}

.media-template-card .media-template-card .tabs {
  padding: 0 32px;
}

.media-template-card .tabs ul {
  margin: 0;
}

.media-template-card .tabs ul li {
  border-radius: 0;
  height: auto;
}

.media-template-card .tabs ul a {
  border: 0;
  display: inline-block;
  padding-bottom: 3px;
}

.media-template-card .tabs ul span {
  color: #333;
}

.media-template-card li + li {
  margin-top: 0;
}

.media-template-card-content {
  padding: 12px 32px 0;
}

.media-template-card-content ul .regular-control-text .tooltip {
  transform: translateY(-5px);
}

.media-template-card-content ul .regular-control-text {
  list-style: none;
  position: relative;
}

.media-template-card-content .button.is-black {
  border-radius: 0;
  padding-left: 24px;
  padding-right: 24px;
}

.for-np + div .pagination-link {
  border: 0;
}

.for-np + div .pagination {
  display: block;
  margin-top: 64px;
}

.for-np + div .pagination-list {
  justify-content: center;
}

.for-np + div .pagination-link.is-current {
  background-color: black;
}

.review-bg {
  background: linear-gradient(
    to right,
    #eee 0%,
    #eee 55%,
    #dbdbdb 55.000001%,
    #dbdbdb 100%
  );
}

.for-np + div .radiomark {
  background-color: transparent;
  border: solid 1px black;
}

.for-np + div .radiomark:after {
  top: 6px;
  left: 6px;
}

.is-link-to-return,
.is-link-to-return:hover,
.is-link-to-return:visited {
  background: transparent;
  border: 0;
  color: #333;
  display: block;
  font-size: 10px;
  float: right;
  padding: 6px 0;
  text-decoration: underline !important;
}

.is-link-to-return:hover {
  background: transparent;
  border: 0;
}

.button.is-back.is-outlined,
.button.is-back.is-outlined:hover,
.button.is-back.is-outlined:visited {
  background-color: transparent;
  border: 0;
  color: #333;
  display: inline-block;
  float: left;
  margin-right: 24px;
}

.button.is-back.is-outlined span {
  display: inline-block;
  padding-right: 12px;
  transform: scale(0.75, 1.5);
}

.button.is-back.is-outlined.is-alone {
  float: right;
  margin: 12px 0;
}

.client-step-np-review .button.is-back.is-outlined + div {
  float: left;
}

.reviewed-media .subtitle {
  border: 0;
}

.reviewed-media .tabs {
  border: 0 !important;
}

.reviewed-media .media-template-card-content p span,
.reviewed-media .media-template-card-content p + span {
  font-size: 15px;
}

.reviewed-media:not(.uplift-review) .media-template-card-content p span,
.reviewed-media:not(.uplift-review) .media-template-card-content p + span {
  background-color: transparent !important;
}

.reviewed-media:not(.uplift-review) .media-template-card-content p span::after,
.reviewed-media:not(.uplift-review)
  .media-template-card-content
  p
  + span::after {
  content: ':';
}

.reviewed-card p {
  font-size: 12px;
  line-height: 18px;
}

.reviewed-card h1 {
  font-weight: 600;
  margin-bottom: 6px !important;
}

.reviewed-card .card-model {
  display: inline-block;
  font-size: 12px;
  margin-bottom: 12px;
  padding: 5px 8px;
  background: #000 !important;
  color: #fff;
}

.for-np + div .reviewed-media .media {
  outline: 0 !important;
}

@media (max-width: 768px) {
  .np-basicFormInit .field {
    width: 100%;
  }

  /* .field.has-addons .control:first-child .button,
    .field.has-addons .control:last-child .button,
    .np-buttons .control:first-child,
    .np-buttons .control:last-child {
        min-height: 140px !important;
        min-width: 120px !important;
    } */

  .np-buttons .control:last-child .button img {
    margin-left: -26px;
  }

  .np-buttons .button img {
    margin-left: -21px;
  }

  .np-buttons .button span {
    padding-top: 48px;
    text-indent: 0;
  }

  .media-template-card-content .button.is-black {
    margin-top: 12px;
  }

  .review-bg {
    background: transparent;
  }

  .hero-body.for-np .columns.is-mobile {
    display: block;
  }

  .hero-body.for-np .columns.is-mobile .column {
    display: block;
    width: 100%;
  }

  .hero-body.for-np .columns.is-mobile .column.is-3 {
    padding-bottom: 0;
    padding-top: 0;
  }

  .hero-body.for-np .columns.is-mobile .column.is-3 .title {
    font-size: 115%;
  }

  .hero-body.for-np
    #partner-signup-steps.partner-signup-newproj-steps
    h3::after {
    display: none;
  }

  .hero-body.for-np #partner-signup-steps.partner-signup-newproj-steps h3 {
    text-align: center;
  }

  .hero-body.for-np #partner-signup-steps.partner-signup-newproj-steps li {
    position: relative;
  }

  .hero-body.for-np #partner-signup-steps.partner-signup-newproj-steps li a {
    margin-left: 0;
    width: 100%;
  }

  .hero-body.for-np
    #partner-signup-steps.partner-signup-newproj-steps
    a.progress-bar--menu-item
    > span,
  .hero-body.for-np {
    height: 4px;
  }

  .hero-body + div > .container {
    padding-top: 148px !important;
  }

  .left-column-for-np .regular-control-text + br,
  .left-column-for-np .regular-control-text + br + br {
    display: none;
  }

  .for-np + .client-step > .container {
    padding: 1.5rem;
  }

  .for-np + .client-step > .container > .column.is-12,
  .for-np + .client-step > .container > .column.is-8,
  .for-np + .client-step > .container > .column.is-6 {
    padding-left: 0;
    padding-right: 0;
  }

  .for-np + .client-step > .container > .column.is-8 {
    padding-bottom: 36px;
  }

  .for-np + .client-step > .container > .column.is-12 > form > .columns,
  .for-np + .client-step > .container > .column.is-12 > form .column {
    margin: 0;
    padding: 0;
  }

  .project-review {
    display: none;
  }

  .for-np + div .pagination {
    display: block;
  }
}

/*
 * CLIENT
 * ------ */

.content-client .input,
.content-client .textarea {
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.content-client .subtitle {
  border-bottom: solid 1px #000;
  font-size: 16px;
  font-weight: 600;
  padding-top: 36px;
}

.content-client .button.previous {
  border: 0;
  position: relative;
}

.content-client .button.previous::before {
  content: '<';
  display: block;
  left: 12px;
  position: absolute;
  top: 6px;
  transform: scale(1, 1.25);
}

.content-client .switch[type='checkbox'].is-success:checked + label::before,
.content-client .switch[type='checkbox'].is-success:checked + label:before {
  background-color: #2f7708;
}

.content-client .checkbox input ~ .checkmark,
.content-client .checkbox input:checked ~ .checkmark,
.content-client .checkbox:hover input:checked ~ .checkmark,
.content-client .checkbox:hover input:checked ~ .checkmark {
  background-color: #fff;
}

.content-client .checkbox .checkmark:before,
.content-client .checkbox .checkmark:after {
  border-color: #000;
}

.content-client .checkmark + p {
  cursor: pointer;
}

.content-client .inputsize-half {
  margin-bottom: 10px;
  width: 50%;
}

.content-client .inputsize-zipcode {
  margin-bottom: 10px;
  margin-right: 12px;
  width: 35%;
}

.content-client .label > .tooltip {
  z-index: 2;
}

.content-client .subtitle-v2 {
  font-size: 25px;
  font-weight: 300;
}

.content-client .table-blue-head th {
  background-color: #a8d7eb;
}

.content-client .table.is-bordered td,
.content-client .table.is-bordered th,
.content-client .partner-step .table td,
.content-client .partner-step .table th,
.content-client .leader-step .table td,
.content-client .leader-step .table th {
  box-shadow: 0 0 0 1px #eee;
}

.content-client .fullsize-border .subtitle {
  border-bottom: 0;
}

.content-client .fullsize-border {
  border-bottom: solid 1px #000;
}

.content-client .select-on-title {
  transform: translate(12px, 22px);
}

.content-client.client-step-5 .subtitle > .tooltip .fas.fa-question-circle {
  margin-top: 20px;
}

/*
 * PARTNER STEPS
 * --------------------------------------- */

.partner-step .container {
  padding: 80px 0;
}

.partner-step .container h1,
.leader-step .container h1 {
  font-weight: 300;
}

.partner-step .container h1 span,
.leader-step .container h1 span {
  display: inline-block;
  margin-right: 15px;
  font-weight: bold;
}

.partner-step .container label,
.leader-step .container label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 30px;
}

.partner-step .container .media label {
  margin-top: 0;
}

.partner-step .container .media .control {
  margin-top: 4px;
}

.media .image.is-64x64 {
  margin: 0;
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}

.media .delete.is-black {
  margin-bottom: 10px;
}

.partner-step .container input,
.partner-step .container select,
.partner-step .container textarea,
.leader-step .container input,
.leader-step .container select,
.leader-step .container textarea {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.title {
  font-size: 25px;
}

.navbar-item img {
  max-height: 2.7rem;
}

.field.is-grouped.is-grouped-centered {
  padding-top: 40px;
  padding-bottom: 120px;
}

.partner-step .container .subtitle,
.leader-step .container .subtitle {
  font-weight: bold;
  color: black;
  padding-bottom: 12px;
  margin-bottom: 40px;
  margin-top: 60px;
  border-bottom: 1px solid black;
  font-size: 16px;
}

.partner-step .container .button.next,
.leader-step .container .button.next {
  border-radius: 0;
  display: inline-block;
  font-size: 12px;
  height: auto;
  letter-spacing: 3px;
  padding: 8px 0px;
}

.partner-step .container .button {
  border-radius: none;
}

.partner-step .container .button.previous,
.leader-step .container .button.previous {
  border: none;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding: 8px 0px;
}

.partner-step .container .button.previous:hover,
.leader-step .container .button.previous:hover {
  background-color: transparent;
  color: black;
}

.partner-step .container .button.previous::before,
.leader-step .container .button.previous::before {
  content: '<';
  display: inline-block;
  font-weight: 400;
  transform: scale(1, 1.5) translateX(-8px);
}

/*
 * INPUT STYLES
 * --------------------------------------- */

.button.is-rounded {
  padding-left: 10px;
  padding-right: 10px;
}

.control.is-expanded .button.is-rounded {
  border-radius: 18px;
  letter-spacing: 3px;
  height: auto;
  font-size: 12px;
  padding: 8px 15px;
  margin-top: 0;
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  background-color: white;
}

.checkmark-grey .checkmark {
  background-color: #cccccc;
}

.checkmark:after,
.checkmark:before {
  content: '';
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after,
.checkbox input:checked ~ .checkmark:before {
  display: block;
}

.checkbox .checkmark:before {
  left: -1px !important;
  top: -1px !important;
  width: 15px;
  height: 15px;
  border: solid black;
  border-width: 0 0 3px 0;
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}

.checkbox .checkmark:after {
  left: 7px !important;
  top: -1px !important;

  width: 15px;
  height: 15px;
  border: solid black;
  border-width: 0 0 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox p {
  line-height: 25px;
}

.checkbox .checkmark {
  background: #fff;
  height: 22px;
  transition: background 0.15s ease-out;
  width: 22px;
}

.checkbox input:checked ~ .checkmark {
  background: #000;
}

.table thead td,
.table thead th {
  border-width: 0 0 1px;
}

/* .logged-page {
    background-color: #eee;
} */

.logged-page {
  background-color: #fafafa;
}

.card-label {
  text-align: right;
  padding-right: 1rem;
  font-weight: bold;
  vertical-align: middle;
}

.table thead td,
.table thead th {
  border-width: 0 0 1px;
}

.card-label {
  text-align: right;
  padding-right: 1rem;
  font-weight: bold;
  vertical-align: middle;
}
.radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 15px;
  min-height: 30px;
}

.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radiomark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
}

.radiomark:after {
  content: '';
  position: absolute;
  display: none;
}

.radio input:checked ~ .radiomark:after {
  display: block;
}

.radio .radiomark:after {
  top: 7px;
  left: 7px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: black;
}

.partner-step .container select {
  background: linear-gradient(
    to right,
    #ffffff 0%,
    #ffffff calc(100% - 40px),
    #000000 calc(100% - 40px),
    #000000 100%
  );
}

.select:not(.is-multiple):not(.is-loading):after {
  border-color: #696969;
}

.step-4-header .button {
  background-color: black;
}

.field .button.is-leaked,
.step-4-header .button.is-leaked {
  border: 2px solid black;
  background-color: transparent;
  font-size: 12px;
  letter-spacing: 2px;
  display: inline-block;
  height: auto;
  letter-spacing: 0;
  margin-left: 15px;
  padding: 6px 6px 6px !important;
}

.step-4-header .button,
.step-4-header .button.is-leaked,
.step-4-header .button.is-black {
  letter-spacing: 0;
  font-weight: bold;
  font-size: 12px;
  height: auto;
  border-width: 1px;
  width: calc(100% / 4.25);
  margin: 0 6px;
}
.field .button.has-icon {
  border-radius: 100%;
}

.field .button.is-rounded {
  border-radius: 18px;
  padding-left: 12px;
  padding-right: 12px;
}

.field .button.is-circle {
  border-radius: 50%;
  width: 36px;
}

.step-4-header {
  display: flex;
  justify-content: space-between;
}

.step-4-header .button:first-of-type {
  margin-left: 0;
}

.step-4-header .button:last-of-type {
  margin-right: 0;
}

/* table {
    border-collapse: separate;
    border-spacing: 2px;
    background-color: transparent !important;

}

table th {
    background-color: #ececec;
    color: black;
    font-weight: bold;
}

table td {
    background-color: white;
    color: black;
    font-weight: normal;
} */

/* table tr,
table th,
table td {
    border: none !important;
    margin: 1px;
} */

.react-datepicker-wrapper input {
  padding: 10px 15px 8px;
}

.react-datepicker-wrapper + .icon,
.react-datepicker-popper + .icon {
  color: white;
  background-color: black;
  padding: 17px;
  font-size: 13px;
}

.react-datepicker__day--selected {
  background-color: black;
}

.react-datepicker {
  border-radius: 0;
}

textarea {
  outline: none;
  resize: none;
  width: 100%;
  padding: 15px;
  margin-bottom: 3px;
  min-height: 150px;
}

.dropzone {
  border: 1px dashed rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 80px;
  padding: 25px 23px 23px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.dropzone .icon {
  display: inline-block;
  width: auto;
  height: auto;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.3);
}

.years-select-expertise,
.months-select-expertise {
  padding-right: 15px;
}

td i.fa {
  color: #666;
}

.is-submenu {
  background-color: white !important;
  color: #0a0a0a !important;
  z-index: 9;
}

.bp-is-completed {
  color: #a9a9a9 !important;
}

.bp-is-pending {
  color: #dbdbdb !important;
}

.bp-is-selected td {
  color: #ffffff !important;
  background-color: #00d1b2 !important;
}

.bp-has-text-dark {
  color: #353535;
}

.bp-has-text-dark a {
  color: #353535;
  font-weight: bold;
}

.bp-has-text-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.bp-partner-banner-text {
  line-height: 28px;
  font-weight: 500;
  font-size: 15px;
}

.colot-line {
  background-color: #000;
  width: 100px;
  height: 10px;
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
}

.colot-line2 {
  background-color: #000;
  width: 100px;
  height: 10px;
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
}

.title-box {
  background: #000;
  color: #fff;
  cursor: default;
  display: inline-block;
  margin: 2px 0 0 12px;
  padding: 8px 18px;
}

.bp-has-background-pink {
  background-color: #ffc5b9;
}

.bp-has-background-blue {
  background-color: #a8d7eb;
}

.bp-has-background-purple {
  background-color: #bab1d0;
}

.bp-has-background-green {
  background-color: #e2fbb9;
}

.client-step-5 th {
  background-color: #bab1d0;
}

.client-step-6 th {
  background-color: #e2fbb9;
}

.new-job-class {
  background-color: #f0e2a1;
}

/*--------------------------------------------------------*/
/*--------------- INICIO CARRINHO NEW JOB ----------------*/
/*--------------------------------------------------------*/

.media + .media {
  border: 0;
}

.customize-content {
  padding: 50px;
}

.customize-content h2 {
  font-size: 25px;
}

.customize-content h4 {
  font-size: 13px;
}

.customize-content p {
  font-size: 13px;
}

.customize-content span.price {
  font-size: 15px;
  font-weight: bold;
}

.customize-baseline {
  height: 8px;
  background: #f0e2a1;
  display: none;
}

.customize-content-bottom {
  flex: 1;
  display: flex;
  line-height: 36px;
}

.customize-content-bottom span {
  flex: 3;
  display: flex;
}

.customize-content-bottom button {
  flex: 1;
  display: flex;
}

.customize-form-search::after {
  content: '\f002';
  font: normal normal normal 22px/1 FontAwesome;
  text-rendering: auto;
  display: inline-block;
  color: #7eb306;
  position: absolute;
  right: 20px;
  top: 15px;
}

.customize-form-search {
  border-radius: 5px;
  border: none;
  height: 35px;
  width: 100%;
  padding-right: 50px;
  color: #93a36e;
  display: inline-block;
}

.customize-form-search + i {
  position: absolute;
  top: 8px;
  right: 20px;
  font-size: 20px;
}

.customize-filter-option {
  padding: 10px;
  border: none;
  border-radius: 0px;
  color: #535353;
  font-size: 1em;
  box-shadow: none;
  height: 60px;
  background: #fff url(/img/bg-arrow-black.png) no-repeat 100% center;
  background-size: 14%;
  appearance: none;
  -webkit-appearance: none;
  display: block;
  width: 300px;
  height: 40px;
}

.customize-filter-content {
  display: inline-block;
  padding-right: 15px;
}

.customize-filter-content label {
  font-weight: bold;
}

.customize-filter-wrapper {
  margin-bottom: 50px;
}

.customize-cart-items {
  position: absolute;
  top: -15px;
  right: -5px;
  background: red;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
}

.resume-cart-heading .customize-cart-items {
  background: #2f7708;
}

.customize-cart-content {
  position: relative;
  text-align: right;
}

.customize-cart {
  position: relative;
}

.customize-cart i {
  font-size: 25px;
}

.customize-cart-amount {
  font-weight: bold;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.customize-cart-content .customize-cart-dropdown {
  padding: 30px;
}

.customize-cart-content .customize-is-hoverable:hover .navbar-dropdown {
  transform: scale(1);
  transition: all 0.4s ease-out;
}

.customize-cart-content .navbar-dropdown {
  border: 5px solid #f0e2a1;
  border-radius: 5px;
  box-shadow: none;
  display: block;
  left: auto;
  min-width: 380px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 95% top;
  transform-origin: 95% top;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  right: 0;
}

.customize-cart-dropdown-item {
  flex: 1;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  /* border-bottom: 2px solid gray; */
}

.btnAtualizar {
  padding: 0 50px 15px;
  display: flex;
}
.btnAtualizar a {
  margin: 0 10px;
}
.borderBottomItem {
  border-bottom: 2px solid gray;
}

.customize-cart-dropdown-item:last-child {
  /*border-bottom: 4px solid #000000;*/
  padding-bottom: 10px;
}

.customize-cart-dropdown-item .cart-item-name {
  flex: 3;
  /* display: flex; */
  text-align: left;
}

.customize-cart-dropdown-item .cart-item-delete {
  flex: 1;
  display: flex;
  text-align: left;
}

.customize-cart-dropdown-item .cart-item-total {
  flex: 1;
  display: flex;
  font-weight: bold;
}

.customize-cart-dropdown-item .cart-item-amount {
  flex: 1;
  display: flex;
  font-weight: bold;
}

.customize-cart-dropdown-item-sum {
  flex: 1;
  display: flex;
  padding-top: 20px;
  padding-bottom: 30px;
}

.customize-cart-dropdown-item-sum .cart-item-total {
  flex: 4;
  display: flex;
  text-align: right;
}

.customize-cart-dropdown-item-sum .cart-item-amount {
  flex: 1;
  display: flex;
  font-weight: bold;
}

.resume-cart-heading h1 {
  float: left;
}

.resume-cart-heading h1 span {
  font-weight: bold;
}

.resume-cart-heading a {
  float: right;
  cursor: inherit !important;
}

.resume-cart-item {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.resume-cart-item-content {
  border-bottom: 2px solid gray;
  padding-bottom: 35px;
}

.resume-cart-item:last-child .resume-cart-item-content {
  border-bottom: 4px solid #000000;
}

.resume-cart-item-name {
  padding-bottom: 0px;
  margin-left: 10px;
}

.resume-cart-item-amount {
  text-align: right;
  padding-bottom: 0px;
  font-weight: bold;
}

.resume-cart-item-action {
  padding-bottom: 0px;
}

.resume-cart-item-border {
  width: 100px;
  height: 15px;
  background: #f0e2a1;
}

.resume-subtotal-total {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: right;
}

.resume-subtotal-amount {
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
}

.resume-subtotal-amount br {
  display: none;
}

.resume-subtotal-amount span {
  display: block;
  float: inherit !important;
}

/*--------------------------------------------------------*/
/*--------------- FIM CARRINHO NEW JOB ----------------*/
/*--------------------------------------------------------*/

/*---------------------------------------------------------------*/
/*--------------- INICIO CUSTOMIZACAO VARIATION  ----------------*/
/*---------------------------------------------------------------*/

.edit-right {
  float: right;
}

.regular-control-text.underline {
  text-decoration: underline;
}

.asset-tooltip-description {
  display: none;
  position: absolute;
  left: 100px;
  width: 450px;
}

.asset-label {
  padding-left: 15px;
  line-height: 30px;
  font-size: 70%;
}

.editCall {
  font-size: 70%;
}

.asset-label.disabled {
  font-size: 70%;
}

.asset-label:hover + .asset-tooltip-description {
  display: inline;
  background: white;
}

/*---------------------------------------------------------------*/
/*------------------ FIM CUSTOMIZACAO VARIATION  ----------------*/
/*---------------------------------------------------------------*/

/*---------------------------------------------------------------*/
/*----------------------- NEW STYLE UPDATES ---------------------*/
/*---------------------------------------------------------------*/

.partner-step .icon.tooltip,
.client-step .icon.tooltip {
  color: #000;
}

.partner-step .label > .tooltip .fas.fa-question-circle::before,
.client-step .label > .tooltip .fas.fa-question-circle::before {
  display: block;
  left: 7px;
  transform: scale(1.1);
}

.project-approval-page .checkbox input ~ .checkmark,
.project-approval-page .checkbox:hover input ~ .checkmark,
.partner-step .checkbox input ~ .checkmark,
.partner-step .checkbox:hover input ~ .checkmark,
.client-step .checkbox input ~ .checkmark,
.client-step .checkbox:hover input ~ .checkmark,
.company-admin .checkbox input ~ .checkmark,
.company-admin .checkbox:hover input ~ .checkmark,
.company-admin .checkbox:hover input:checked ~ .checkmark,
.specialist-admin .checkbox input ~ .checkmark,
.specialist-admin .checkbox:hover input ~ .checkmark,
.specialist-admin .checkbox:hover input:checked ~ .checkmark,
.contract-payment-admin .checkbox input ~ .checkmark,
.contract-payment-admin .checkbox:hover input ~ .checkmark,
.contract-payment-admin .checkbox:hover input:checked ~ .checkmark {
  background-color: #fff;
}

.company-admin h1 {
  font-weight: bold;
  color: black;
  padding-bottom: 12px;
  margin-bottom: 40px;
  margin-top: 60px;
  border-bottom: 1px solid black;
  font-size: 16px;
}

.company-admin p {
  font-size: 15px;
  color: #333333;
  line-height: 24px;
  font-family: var(--font-rubik);
  margin: 0;
  padding: 0;
}

.company-admin .table.is-bordered td {
  box-shadow: 0 0 1px #707070;
  font-size: 13px;
  padding: 10px 12px;
  text-align: center;
}

.project-approval-page .checkbox input:checked ~ .checkmark,
.project-approval-page .checkbox input:checked ~ .checkmark,
.partner-step .checkbox input:checked ~ .checkmark,
.client-step .checkbox input:checked ~ .checkmark,
.partner-step .checkbox:hover input:checked ~ .checkmark,
.client-step .checkbox:hover input:checked ~ .checkmark {
  background-color: #000;
}

.project-approval-page .checkbox:hover input ~ .checkmark::before,
.partner-step .checkbox:hover input ~ .checkmark::before,
.client-step .checkbox:hover input ~ .checkmark::before,
.company-admin .checkbox:hover input:not(:checked) ~ .checkmark::before,
.specialist-admin .checkbox:hover input:not(:checked) ~ .checkmark::before,
.contract-payment-admin
  .checkbox:hover
  input:not(:checked)
  ~ .checkmark::before {
  left: 1px;
  top: 1px;
  width: 15px;
  height: 15px;
  border: solid #dedede;
  border-width: 0 0 3px 0;
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.project-approval-page .checkbox:hover input ~ .checkmark::after,
.partner-step .checkbox:hover input ~ .checkmark::after,
.client-step .checkbox:hover input ~ .checkmark::after,
.company-admin .checkbox:hover input:not(:checked) ~ .checkmark::after,
.specialist-admin .checkbox:hover input:not(:checked) ~ .checkmark::after,
.contract-payment-admin
  .checkbox:hover
  input:not(:checked)
  ~ .checkmark::after {
  left: 9px;
  top: 1px;
  width: 15px;
  height: 15px;
  border: solid #dedede;
  border-width: 0 0 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.project-approval-page .checkbox:hover input ~ .checkmark::after,
.project-approval-page .checkbox:hover input ~ .checkmark::before,
.partner-step .checkbox:hover input ~ .checkmark::after,
.partner-step .checkbox:hover input ~ .checkmark::before,
.client-step .checkbox:hover input ~ .checkmark::after,
.client-step .checkbox:hover input ~ .checkmark::before,
.company-admin .checkbox:hover input ~ .checkmark::after,
.company-admin .checkbox:hover input ~ .checkmark::before,
.specialist-admin .checkbox:hover input ~ .checkmark::after,
.specialist-admin .checkbox:hover input ~ .checkmark::before,
.contract-payment-admin .checkbox:hover input ~ .checkmark::after,
.contract-payment-admin .checkbox:hover input ~ .checkmark::before {
  content: '';
  position: absolute;
  display: block;
}

.project-approval-page .checkbox .checkmark::before,
.project-approval-page .checkbox .checkmark::after,
.project-approval-page .checkbox:hover input:checked ~ .checkmark::before,
.project-approval-page .checkbox:hover input:checked ~ .checkmark::after,
.partner-step .checkbox .checkmark::before,
.partner-step .checkbox .checkmark::after,
.partner-step .checkbox:hover input:checked ~ .checkmark::before,
.partner-step .checkbox:hover input:checked ~ .checkmark::after,
.client-step .checkbox .checkmark::before,
.client-step .checkbox .checkmark::after,
.client-step .checkbox:hover input:checked ~ .checkmark::before,
.client-step .checkbox:hover input:checked ~ .checkmark::after {
  border-color: #fff;
}

.project-approval-page .card {
  display: inline-block;
}

.project-approval-page .media.media-template-card {
  outline: 0 !important;
}

.has-lateral-padding {
  margin-bottom: 64px;
  padding: 0 36px;
}

.decline-block {
  background-color: #fff;
  border: solid 3px red;
  font-size: 12px;
  padding: 48px;
  margin-bottom: 96px;
}

.decline-block .textarea {
  border: solid 1px #535353;
  border-radius: 0;
}

.decline-block .title {
  font-size: 16px;
  margin-bottom: 0.75rem;
  text-transform: none;
}

.decline-block .subtitle {
  font-size: 12px;
  line-height: 16px;
}

.decline-block .label.is-small {
  float: right;
}

.project-definition-header .tag:not(body).is-warning,
.tag:not(body).is-warning.tag-project-name,
.project-ongoing-header .tag:not(body).is-warning {
  background-color: #fff291;
  border-radius: 0;
  font-weight: normal;
}

.table td.is-warning {
  background: #000;
  color: #fff;
  font-weight: bold;
  vertical-align: middle;
}

.table td.is-warning a {
  color: #fff;
  font-weight: bold;
}

.table td.is-warning a:hover {
  color: #ccc;
}

.partnersApproval {
  margin-bottom: 3rem;
}

.project-definition .title,
.partnersApproval .title,
.project-ongoing .title {
  position: relative;
  font-weight: 600;
}

.partner-step .container h1 {
  font-weight: 600;
}

.partnersApproval .title-1::after {
  background-color: #000;
}

.partnersApproval .title-2::after {
  background-color: #000;
}

.project-definition .title-1::after {
  background-color: #000;
}

.project-definition .title-2::after {
  background-color: #000;
}

.project-definition .title-3::after {
  background-color: #000;
}

.project-definition .title-4::after {
  background-color: #000;
}

.project-ongoing .title-1::after {
  background-color: #000;
}

.project-ongoing .title-2::after {
  background-color: #000;
}

.project-ongoing .title-3::after {
  background-color: #000;
}

.project-ongoing .title-4::after {
  background-color: #000;
}

.project-ongoing .title-5::after {
  background-color: #000;
}

.project-ongoing .title-6::after {
  background-color: #000;
}

.project-definition .title span,
.partnersApproval .title span,
.project-ongoing .title span {
  font-weight: bold;
}

.partnersApproval .smaller-font {
  font-size: 85%;
  line-height: 21px;
}

.partnersApproval .media-card-content-top {
  line-height: 22px;
}

.partnersApproval .card {
  box-shadow: none;
}

.partnersApproval .button.is-black {
  border-radius: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.partnersApproval .button.is-black.is-outlined {
  display: inline-block;
  height: 48px;
  padding: 12px 32px;
}

.button-selectPartnet-logo {
  transform: translate(-16px, 5px);
  width: 36px;
}

.partnersApproval .button-selectPartnet-icon {
  width: 32px;
}

.partnersApproval .columns.reasons-form-select-fields {
  margin-bottom: -12px;
}

.partnersApproval .select select {
  border: 0;
  border-radius: 0;
}

.partnersApproval .other-reasons .input {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 16px;
}

.partnersApproval .no-reason-at-all p {
  font-size: 50%;
}

.partnersApprovalReasons {
  margin-bottom: 36px;
}

/* .partnersApprovalReasons a {
    padding-left: 24px;
    padding-right: 24px;
} */

.upload-button-style {
  border-radius: 0;
  height: 42px;
  padding: 8px 12px;
  width: 16px;
}

.upload-button-icon {
  transform: translate(10px, 3px);
  width: 16px;
}

.project-definition .card {
  box-shadow: none;
}

.project-definition .has-addons {
  background-color: #999;
}

.project-definition .has-addons .button {
  border: 0;
  padding: 0;
}

.project-definition .card .card-header-title {
  font-size: 20px;
  font-weight: 300;
}

.project-definition .has-addons .button span {
  display: inline-block;
  height: 36px;
  margin: 0 !important;
  padding: 7px 0 12px;
  width: 36px;
}

.project-definition .bold-button,
.project-definition .italic-button {
  border-right: solid 1px #fff;
}

.project-definition .card-mover {
  background-color: transparent;
  border: 0;
  display: block;
  width: 36px;
  height: 36px;
  position: relative;
  margin-right: 24px;
}

.project-definition .card-mover span {
  background-color: #fff;
  height: 1px;
  position: absolute;
  left: 6px;
  width: 24px;
  top: 18px;
}

.project-definition .card-mover span::before,
.project-definition .card-mover span::after {
  background-color: #fff;
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
}

.project-definition .card-mover span::before {
  margin-top: -6px;
}

.project-definition .card-mover span::after {
  margin-top: 6px;
}

.project-definition .card-header {
  box-shadow: none;
}

.project-definition .card-header-close {
  margin-top: -37px;
  width: 36px;
  height: 36px;
  border: solid 1px #000000;
  border-bottom: none;
  margin-right: 10px;
  display: inline-block;
}

.project-definition .card-header-close,
.project-definition .card-header-close:visited,
.project-definition .card-header-close:active {
  color: inherit;
}

.project-definition .briefing-item {
  padding: 14px 0 6px 24px;
  border-bottom: solid 1px #000;
  min-height: 45px;
  font-size: 80%;
}

.project-definition .briefing-item .fas {
  transform: scale(1.5);
}

.project-definition .briefing-item-subtitle,
.project-definition .upload-item-subtitle {
  border-bottom: solid 1px #000;
  font-size: 90%;
  margin-bottom: 0;
  padding-bottom: 12px;
}

.project-definition .upload-item-subtitle {
  margin-bottom: 6px;
}

.project-definition .upload-item .delete {
  background-color: transparent;
  transform: scale(1.5);
}

.project-definition .upload-item .delete::before,
.project-definition .upload-item .delete::after {
  background-color: #000;
}

.project-definition .upload-item .delete::after {
  width: 1.5px;
}

.project-definition .upload-item .delete::before {
  height: 1.5px;
}

.project-definition .upload-item a {
  color: #000;
  font-size: 80%;
}

.project-definition .bp-toolbar,
.project-ongoing .bp-toolbar {
  padding: 24px 0 48px;
}

.project-definition .rbc-month-view,
.project-ongoing .rbc-month-view {
  border: 0;
}

.project-definition .rbc-header,
.project-ongoing .rbc-header {
  border-bottom: solid 4px #fff;
  font-weight: normal;
}

.project-definition .rbc-month-row + .rbc-month-row,
.project-ongoing .rbc-month-row + .rbc-month-row {
  border-top: solid 4px #fff;
}

.project-definition .rbc-header + .rbc-header,
.project-definition .rbc-day-bg + .rbc-day-bg,
.project-ongoing .rbc-header + .rbc-header,
.project-ongoing .rbc-day-bg + .rbc-day-bg {
  border-left: solid 4px #fff;
}

.project-definition .rbc-date-cell,
.project-ongoing .rbc-date-cell {
  text-align: left;
  font-size: 90%;
  padding: 8px;
}

.project-definition .rbc-row .rbc-date-cell:first-child,
.project-definition .rbc-row .rbc-date-cell:last-child,
.project-ongoing .rbc-row .rbc-date-cell:first-child,
.project-ongoing .rbc-row .rbc-date-cell:last-child {
  color: #96dede;
}

.project-definition .schedule-title,
.project-ongoing .schedule-title {
  background-color: #a8d7eb;
  font-size: 25px;
  font-weight: 300;
  text-align: center;
  padding: 1rem;
}

.project-definition .calendar-button,
.project-definition .timeline-button,
.project-ongoing .calendar-button,
.project-ongoing .timeline-button {
  border-radius: 0;
  padding-left: 24px;
  padding-right: 24px;
}

.project-definition .timeline-button:not(.is-active),
.project-definition .calendar-button:not(.is-active),
.project-ongoing .timeline-button:not(.is-active),
.project-ongoing .calendar-button:not(.is-active) {
  border-color: #000;
}

.project-definition .timeline-button.is-active,
.project-definition .calendar-button.is-active,
.project-ongoing .timeline-button.is-active,
.project-ongoing .calendar-button.is-active {
  background-color: #c7e5f2;
  border: 0;
  color: #000;
}

.project-definition .timeline-button:focus:not(:active),
.project-definition .calendar-button:focus:not(:active) {
  outline: 0;
  box-shadow: none;
}

.project-definition .top-label-title {
  margin-bottom: 2px;
}

.project-definition .timeline,
.project-ongoing .timeline {
  margin-left: 4px;
}

.project-definition .timeline-header,
.project-definition .timeline .timeline-item .timeline-marker,
.project-ongoing .timeline-header,
.project-ongoing .timeline .timeline-item .timeline-marker {
  display: none;
}

.project-definition .timeline .timeline-item,
.project-ongoing .timeline .timeline-item {
  padding: 16px 0;
}

.project-definition .timeline .timeline-item:nth-child(odd),
.project-ongoing .timeline .timeline-item:nth-child(odd) {
  border-right: solid 2px #ececec;
  margin-left: -100%;
  text-align: right;
  width: 100%;
}

.project-definition .timeline .timeline-item:nth-child(odd) .heading,
.project-ongoing .timeline .timeline-item:nth-child(odd) .heading {
  float: right;
}

.project-definition .timeline .timeline-item:nth-child(even),
.project-ongoing .timeline .timeline-item:nth-child(even) {
  border-left: solid 2px #ececec;
  margin-left: -2px;
}

.project-definition .timeline .timeline-content,
.project-ongoing .timeline .timeline-content {
  padding: 0;
  width: 100%;
}

.project-definition .timeline .timeline-content .heading,
.project-ongoing .timeline .timeline-content .heading {
  background-color: #c7e5f2;
  display: inline-block;
  font-weight: 600;
  padding: 8px 24px;
  margin-bottom: 0;
}

.project-definition .timeline .timeline-content .heading + p,
.project-ongoing .timeline .timeline-content .heading + p {
  background-color: #ececec;
  display: block;
  clear: both;
  font-weight: 400;
  padding: 8px 24px;
}

.project-definition .timeline .timeline-item::before,
.project-ongoing .timeline .timeline-item::before {
  width: 0;
}

.project-definition .timeline .timeline-item-first,
.project-ongoing .timeline .timeline-item-first {
  padding-top: 36px;
}

.project-definition .timeline .timeline-item-last,
.project-ongoing .timeline .timeline-item-last {
  padding-bottom: 36px;
}

.project-definition .project-details-review .card,
.project-ongoing .project-ongoing-review .card {
  display: inline-block;
  width: 100%;
}

card
  project-details-review-card
  .project-definition
  .project-details-review
  .project-details-review-card,
.project-definition .project-details-review .project-details-brief-card,
.project-ongoing .project-ongoing-review .project-ongoing-review-card {
  width: 100%;
}

.project-definition .project-details-review .project-details-brief-card .card {
  box-shadow: none;
}

.project-definition .project-details-review .project-details-brief-card {
  margin-bottom: 12px;
}

.project-definition .project-details-review h1 {
  margin-top: 24px;
}

.project-details-review .media-template-card,
.project-ongoing .media-template-card {
  outline: 0 !important;
}

.project-definition .project-po-form .input {
  border: 0;
  box-shadow: none;
}

.project-ongoing .briefing-scroll-list {
  background-color: #fff;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}

.project-ongoing .card-header-title {
  font-size: 20px;
  font-weight: 300;
}

.project-ongoing .card-header-title,
.project-ongoing .public-DraftEditor-content {
  padding-left: 24px;
  padding-right: 24px;
}

.project-ongoing .briefing-scroll-list .card,
.project-ongoing .briefing-scroll-list .card-header {
  box-shadow: none;
}

.project-ongoing .briefing-scroll-list .public-DraftEditor-content {
  min-height: auto;
}

.project-ongoing .is-rating .modal-background,
.project-ongoing .is-feedback .modal-background {
  background-color: rgba(10, 10, 10, 0.66);
}

.project-ongoing .is-rating .modal-content,
.project-ongoing .is-feedback .modal-content {
  overflow: visible;
}

.project-ongoing .is-rating .card-content-mask {
  overflow: auto;
  margin: -0.5rem;
  max-height: 400px;
}

.project-ongoing .is-feedback .modal-content .card {
  background-color: #f0f0f0;
  border: solid 2px #fd1313;
}

.project-ongoing .is-rating .modal-content {
  background-color: #fff;
  border: solid 1px #000;
}

.project-ongoing .is-rating .modal-content .card {
  background-color: #fff;
  box-shadow: none;
}

.project-ongoing .is-rating .modal-content .card-header,
.project-ongoing .is-feedback .modal-content .card-header {
  height: 0;
}

.project-ongoing .is-rating .modal-content .card-header-title,
.project-ongoing .is-rating .modal-content h5,
.project-ongoing .is-feedback .modal-content .card-header-title,
.project-ongoing .is-feedback .modal-content h5 {
  display: none;
}

.project-ongoing .is-rating .modal-content h6 {
  font-weight: 300;
  font-size: 25px;
}

.project-ongoing .is-rating .star-field {
  border-left: 0;
  border-right: 0;
}

.project-ongoing .is-rating .star-field .label {
  float: left;
  font-weight: 400;
  padding-left: 5%;
  display: inline-block;
  width: 60%;
}

.project-ongoing .is-rating .star-field .rating {
  float: left;
  padding-left: 2.5%;
  display: inline-block;
}

.project-ongoing .is-rating .modal-content .card-header-icon {
  background-color: #fff;
  border: solid 1px #000;
  border-bottom: 0;
  color: #000;
  height: 36px;
  width: 36px;
  position: absolute;
  right: 1px;
  top: -44px;
}

.project-ongoing .project-ongoing-review .card.no-content {
  width: 100%;
}

body .project-ongoing .is-rating .rating > input:checked ~ label {
  color: #000;
}

.project-ongoing .is-feedback .modal-content .card-header-icon {
  background-color: #fd1313;
  color: #fff;
  height: 36px;
  width: 36px;
  position: absolute;
  right: -2px;
  top: -36px;
}

.project-ongoing .is-feedback .modal-content textarea {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.project-ongoing .is-feedback .modal-content .button.is-black {
  border-radius: 18px;
  margin: -48px 0 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.project-ongoing .is-rating .button.is-black {
  padding-left: 36px;
  padding-right: 36px;
  margin: -12px 0 36px;
}

.partner-responsible .fa-question-circle,
.company-admin .fa-question-circle,
.plan-admin .fa-question-circle {
  transform: scale(1.35) translateY(-1px);
}

.partner-responsible .subtitle .fa-question-circle,
.company-admin .subtitle .fa-question-circle,
.specialist-admin .subtitle .fa-question-circle,
.references-admin .subtitle .fa-question-circle,
.brands-users-admin .subtitle .fa-question-circle {
  transform: scale(0.85) translateY(-2px);
}

.partner-responsible .subtitle > .tooltip .fas.fa-question-circle::after,
.company-admin .subtitle > .tooltip .fas.fa-question-circle::after,
.specialist-admin .subtitle > .tooltip .fas.fa-question-circle::after,
.references-admin .subtitle > .tooltip .fas.fa-question-circle::after,
.brands-users-admin .subtitle > .tooltip .fas.fa-question-circle::after {
  top: 8px;
  width: 75%;
  height: 75%;
}

.tooltip:hover {
  z-index: 9999 !important;
}

#root .breadcrumb.is-medium a {
  color: black;
}

#root .box table {
  border-color: transparent;
}

#root .box table a {
  color: black;
}

#root .navbar-dropdown a.navbar-item.is-active {
  color: black;
  font-weight: 600;
}

#root .navbar-dropdown a.navbar-last-item {
  color: black;
  font-weight: 300;
}

#root a.forgot-pass,
#root a.forgot-pass:visited,
#root a.forgot-pass:hover,
#root a.forgot-pass:active {
  color: black;
}

.carousel-cards-mask {
  display: block;
  margin-left: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel-cards-mask .carousel-cards {
  transition: all 0.4s ease-out;
  width: 100%;
}

.carousel-cards .carousel-cards-item {
  display: inline-block;
  overflow: hidden;
}

.carousel-cards-buttons .is-opaque {
  cursor: default;
  opacity: 0.15;
  display: none;
}

.carousel-cards-buttons button {
  background-color: black;
  border: 0;
  cursor: pointer;
  color: white;
  margin-left: 3px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 36px;
  transition: all 0.15s ease-out;
}

.carousel-cards-buttons {
  float: right;
  position: relative;
  margin-top: -54px;
}

/* .carousel-cards-mask button:first-child {
    float: left;
}

.carousel-cards-mask button:last-child {
    float: right;
} */

.carousel-cards-buttons span {
  display: inline-block;
  transform: scale(1, 1.5) translate(2px, -1px);
}

.carousel-cards-item .media {
  overflow: hidden;
  border: solid 1px #dedede;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  padding: 12px;
}

.carousel-cards-item .card-content {
  padding: 1rem 0.5rem;
}

.taxLine td {
  background-color: #cbede0;
}

.costLine td {
  background-color: #bab1d1;
}

.preview-cards .carousel .carousel-container .card {
  border: 0;
  box-shadow: none;
}

.preview-cards .carousel-cards-item .media {
  border: 0;
}

.preview-cards .carousel-cards-item .media,
.preview-cards.preview-cards-awards .carousel .carousel-item .card {
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.125), 0 6px 10px rgba(0, 0, 0, 0.125);
}

.preview-cards.preview-cards-awards .carousel-item .subtitle {
  padding-left: 0;
}

.preview-cards .carousel-cards-item .media p {
  line-height: 18px;
}

.preview-cards .carousel-cards-item .media p strong {
  display: inline-block;
  padding: 0.35rem 0;
}

.preview-cards-not-media .carousel-item {
  padding: 1rem 0.5rem;
}

.preview-cards-not-media .carousel-item div {
  border-radius: 3px;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.125), 0 6px 10px rgba(0, 0, 0, 0.125);
  height: 100%;
  overflow: hidden;
}

.preview-cards-cases .carousel-item {
  width: 50%;
}

.preview-cards-not-media .carousel-item figure + p,
.preview-cards-not-media .responsive-video + p,
.preview-cards-not-media .responsive-video-container + p {
  padding: 1rem 1.5rem 1.25rem;
}

.preview-cards-not-media .carousel-item figure {
  background-color: #dedede;
  margin: 0;
}

.preview-hr {
  border-bottom: 1px solid #f1f1f1;
  margin: 2rem -1.5rem 2rem;
}

.preview-hr-hidden {
  display: none;
}

.preview-cards .subtitle {
  padding-left: 0.5rem;
}

.responsive-video-container {
  background-color: #000;
  position: relative;
  padding-top: 0;
}

.responsive-video-container video {
  transform: translateY(0%);
  max-height: 350px;
  top: 50%;
  position: relative;
}

.preview-cards-not-media .carousel-item .responsive-video-container {
  border-radius: 0 !important;
  height: auto;
  max-height: 350px;
}

.responsive-video {
  border-radius: 0 !important;
  height: 0;
  margin: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  width: auto !important;
  height: auto !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.responsive-video > div {
  border-radius: 0 !important;
  height: 100%;
  width: 100%;
}

.responsive-video iframe,
.responsive-video object,
.responsive-video embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media (max-width: 1024px) {
  .preview-area {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .preview-page-container .hero-body .container {
    width: 100% !important;
  }
}

.eventListForMobile {
  display: none;
}

@media (max-width: 768px) {
  .preview-area {
    padding: 0 !important;
  }
  .preview-cards-cases .carousel-item {
    width: 100%;
  }
  .invitation-list-boxes {
    padding: 24px 18px;
  }
  .invitation-title {
    padding-top: 12px;
  }
  .hero-head.is-fixed + div > .hero-body.invitation-container {
    padding-bottom: 12px !important;
  }
  .invitation-list-boxes .button.is-small {
    border-radius: 0;
    margin-right: 6px;
  }
  .invitation-list-boxes .tag {
    border-radius: 0;
    height: 27px;
  }

  .not-for-mobile {
    display: none;
  }

  .invitation-container + div > .container .react-tabs__tab-panel,
  .client-list-container {
    padding-left: 18px;
    padding-right: 18px;
  }

  .invitation-tabs {
    padding: 0 18px;
  }

  .rbc-month-header .rbc-header span {
    font-size: 0;
  }

  .rbc-month-header .rbc-header:nth-child(1)::after {
    content: 'D';
  }

  .rbc-month-header .rbc-header:nth-child(2)::after {
    content: 'S';
  }

  .rbc-month-header .rbc-header:nth-child(3)::after {
    content: 'T';
  }

  .rbc-month-header .rbc-header:nth-child(4)::after {
    content: 'Q';
  }

  .rbc-month-header .rbc-header:nth-child(5)::after {
    content: 'Q';
  }

  .rbc-month-header .rbc-header:nth-child(6)::after {
    content: 'S';
  }

  .rbc-month-header .rbc-header:nth-child(7)::after {
    content: 'S';
  }

  body .container-calendar .schedule-container {
    height: 440px;
    margin-bottom: 24px;
  }

  body .container-calendar .rbc-month-view {
    height: 92.5%;
  }

  body .rbc-off-range > a {
    color: #f0f0f0 !important;
  }

  body .rbc-event {
    font-size: 0;
    padding: 0;
    height: 8px;
    width: 8px;
  }

  body .rbc-row-segment {
    margin: -6px 0 0 6px;
  }

  body .rbc-event .rbc-event-content {
    display: none;
  }

  .project-definition .rbc-header + .rbc-header,
  .project-definition .rbc-day-bg + .rbc-day-bg,
  .project-ongoing .rbc-header + .rbc-header,
  .project-ongoing .rbc-day-bg + .rbc-day-bg,
  .project-definition .rbc-month-row + .rbc-month-row,
  .project-ongoing .rbc-month-row + .rbc-month-row,
  .project-definition .rbc-header,
  .project-ongoing .rbc-header {
    border-width: 2px;
  }

  .eventListForMobile {
    display: block;
    margin-bottom: 48px;
    font-size: 85%;
  }

  .eventListForMobile li {
    background-color: #d1ebf7;
    display: block;
    border-radius: 3px;
    margin: 0 6px 3px;
    padding: 6px 12px;
  }
}

.invitation-tabs .react-tabs__tab--selected {
  background-color: inherit;
  color: #fff;
}

.navbar-item.has-dropdown .navbar-dropdown {
  height: 24px;
}

.navbar-item.has-dropdown.is-active .navbar-dropdown {
  height: auto;
}

body .project-definition .bp-toolbar,
body .project-ongoing .bp-toolbar {
  margin-top: -12px;
  padding: 0 0 14px;
}

body .schedule-container {
  height: 660px;
}

body .rbc-show-more {
  font-size: 75%;
  font-weight: normal;
}

body .rbc-month-view {
  height: 95%;
}

#navbarMenu .navbar-dropdown.navbar-notification-window {
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  height: 240px;
  overflow-x: hidden;
  overflow-y: auto;
  /* transform: scale(1); */
  width: 380px;
}

/* #navbarMenu .navbar-link .bellNotificationYes {
    color: #ffdd57 !important;
} */

#navbarMenu .navbar-link .bellNotificationNo {
  color: #fff !important;
}

#navbarMenu .navbar-dropdown.navbar-notification-window {
  padding: 0;
}

#navbarMenu .notificationViewAll {
  background-color: #fff !important;
  border-bottom: double 3px #dbdbdb;
  padding-bottom: 0;
  padding-top: 0;
}

#navbarMenu .notificationViewAll a {
  color: #000;
  display: inline-block;
  text-align: right;
  width: 100%;
}

body .kwGyVs {
  animation-duration: 730ms;
  animation-delay: 5s;
}

#navbarMenu .navbar-dropdown.navbar-notification-window .navbar-item,
#navbarMenu .navbar-dropdown.navbar-notification-window .navbar-item p {
  display: block;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  width: 100%;
  white-space: normal;
}

#navbarMenu .navbar-dropdown.navbar-notification-window .navbar-item {
  background-color: #f9f9f9;
}

#navbarMenu .navbar-dropdown.navbar-notification-window .navbar-item small {
  cursor: default;
  font-size: 10px;
  opacity: 0.5;
}

#navbarMenu
  .navbar-dropdown.navbar-notification-window
  .navbar-item:nth-child(odd) {
  background-color: #f1f1f1;
}

.customized-projects-header {
  margin-top: -39px;
}

.customized-projects-header .is-9,
.customized-projects-header .is-6,
.customized-projects-header .is-3 {
  padding: 28px 0;
}

.customized-projects-header .green {
  background-color: #cbede0;
  padding-left: 28px;
  padding-right: 28px;
}

.customized-projects-body {
  margin: 0 auto;
}

.customized-projects-body .is-7 {
  padding-left: 28px;
  padding-right: 28px;
  overflow-y: auto;
  max-height: 600px;
}

.customized-projects-body .is-5 {
  padding-left: 24px;
}

.customized-projects-body .cart-item-delete a {
  background-color: #ececec;
  width: 30px;
  height: 30px;
  line-height: 35px;
  border-radius: 25px;
  display: inline-block;
  color: #000;
  font-weight: 700;
  margin-left: 24px;
  text-align: center;
}

.customized-projects-body .cart-item-total {
  text-align: center;
}

.customized-projects-body .cart-item-total-num {
  background-color: #ececec;
  cursor: default;
  display: inline-block;
  font-weight: 400;
  padding: 3px 6px;
  margin: 0 auto;
  min-width: 36px;
  line-height: 40px;
}

.customize-cart-dropdown-item-sum .cart-item-amount {
  display: inline-block;
  text-align: right;
}

.customized-projects-body .cart-item-amount {
  padding-right: 16px;
  min-width: 120px;
}

.customized-projects-body .customize-content h4 {
  font-weight: 400;
}

.customized-projects-body .customize-content h2 {
  font-size: 18px;
}

.customized-projects-body .customize-cart-dropdown-updated .button.is-black {
  width: 100%;
}

.customized-projects-body .media-template-card .button.is-black::after {
  content: '»';
  padding-left: 8px;
}

.customized-projects-body .media-template-card .toggleTrigger {
  display: inline-block;
}

.customized-projects-body .media-template-card .toggleTrigger::after {
  content: '>';
  display: inline-block;
  padding: 0 12px;
  transform: rotate(-90deg) scale(1, 2);
  font-size: 12px;
  position: relative;
  top: -2px;
  left: 3px;
}

.customized-projects-body .media-template-card .toggleTrigger.is-closed::after {
  transform: rotate(90deg) scale(1, 2);
}

.customized-projects-body .media-template-card .customize-content {
  padding: 24px;
}

.customized-projects-body .customize-cart-dropdown .customize-card-final {
  padding: 12px 12px 6px;
}

.for-np + div .client-container-build-project {
  padding-bottom: 0;
}

.build-closure {
  padding-bottom: 128px;
}

.customized-projects-body h6 {
  font-size: 0.75rem;
  font-weight: 600;
  padding: 32px 0 16px;
}

.customized-projects-body .customize-cart i {
  color: #000;
  font-size: 16px;
  padding-right: 12px;
}

.customized-projects-body .customize-cart-items {
  font-size: 10px;
  height: 19px;
  right: 1px;
  width: 19px;
}

.customized-projects-body .cartResume {
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
}

.customized-projects-header .is-9 h1 {
  font-weight: 700;
  margin-bottom: 6px;
}

.customized-projects-header .blue {
  background-color: #c7e5f2;
}

.customized-projects-header .blue .boxTitle.right {
  border: 0;
}

.customized-projects-header .is-3 h4 {
  font-size: 115%;
  margin-bottom: 0;
}

.customize-filter-wrapper,
.customize-search-wrapper {
  padding: 0 18px;
  margin: 12px 0 0;
}

.customize-search-wrapper {
  padding-right: 30px;
  margin-top: 39px;
}

.customize-search-wrapper .level-item {
  width: 100%;
}

.customize-search-wrapper .field {
  width: 100%;
}

.hr-style {
  background-color: rgb(0, 0, 0);
  height: 1px;
  margin: 0 18px;
}

.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  body
    .for-np
    + .client-step
    > .container
    > .column.is-12
    > form
    > .columns.customized-projects-header {
    margin: -1rem -1.5rem 1.5rem !important;
    /* padding: 0 1.5rem !important; */
  }
  body
    .for-np
    + .client-step
    > .container
    > .column.is-12
    > form
    > .columns.customized-projects-header
    > .column {
    padding: 1.5rem;
  }

  body .customized-projects-body .is-7 {
    overflow: auto;
    max-height: inherit;
  }

  body .columns.customized-projects-header .regular-control-text {
    line-height: 18px;
  }

  body .customize-filter-content {
    display: block;
    margin-bottom: 12px !important;
  }

  body .customized-projects-body + hr {
    margin: 36px 0 0 !important;
  }

  body .customized-projects-body .media-template-card .button.is-black::after {
    padding-left: 0;
    transform: rotate(-90deg) translate(-1px, 3px);
  }

  body .customized-projects-header .is-3 h4 {
    display: inline-block;
    /* text-align: left; */
    width: 60%;
  }

  body .customized-projects-header .is-3 h4 + .field {
    display: inline-block;
    float: right;
    width: 40%;
  }

  body .customized-projects-body .is-7 h6 {
    padding-top: 0;
  }

  body .customized-projects-body .customize-baseline {
    height: 4px;
  }

  body .customized-projects-body .media-template-card .toggleTrigger {
    margin: 30px 0 15px;
    width: 100%;
  }

  body .customized-projects-header .is-3 h4 + .field label {
    display: none;
  }

  body .customized-projects-header .control.is-expanded .button.is-rounded {
    border-radius: 24px;
    letter-spacing: 1px;
    line-height: 14px;
    padding-left: 22px;
    padding-right: 22px;
  }

  .mobile-only {
    display: block;
  }
}

/* Uplift */

.uplift .title-for-np {
  font-family: 'Helvetica Neue LT Std';
  font-weight: bold;
  font-size: 32px;
}

.uplift .label {
  font-weight: 600;
}

body .uplift .control .input,
body .uplift .control .bp__control {
}

.hero-head.is-fixed + div > .hero-body.for-np {
  box-shadow: 1px 0 1px rgba(0, 0, 0, 0.25);
}

.uplift .control .radiomark {
  border: solid 2px #000;
}

.uplift .control .radiomark:after {
  background-color: #000;
  top: 5px;
  left: 5px;
}

.uplift.partner-projects,
.uplift.client-project-list {
  background: #fff;
  padding-top: 65px;
  min-height: 100vh;
}

.uplift.partner-projects h1,
.uplift.client-project-list h1 {
  font-size: 25px;
  position: relative;
  width: auto;
  font-weight: 400;
  margin: 0 0 48px;
}

.uplift.partner-projects h1 span,
.uplift.client-project-list h1 span {
  position: relative;
}

.uplift.partner-projects h1 span::after,
.uplift.client-project-list h1 span::after {
  background-color: #000;
  position: absolute;
  display: block;
  content: '';
  height: 8px;
  margin-top: 12px;
  top: 100%;
  width: 80%;
}

.uplift .card-boxes .card,
.uplift .card-box .card,
.uplift.project-invite .reviewed-media .media,
.uplift.project-invite .card {
  border: 0;
}

.uplift.project-invite .checkbox input ~ .checkmark {
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.uplift .tag {
  background: no-repeat 6px center;
  background-size: 24px;
  padding-left: 36px;
}

.uplift .tag.tag-accepted {
  background-image: url('/img/icon-success.svg');
}

.uplift .tag.tag-declined {
  background-image: url('/img/icon-error.svg');
}

.uplift .tag.tag-expired {
  background-image: url('/img/icon-warning.svg');
}

.uplift.client-project-list thead {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.table-main {
  overflow: hidden;
}

.uplift.client-project-list thead th {
  background-color: #434343;
  cursor: default;
  border: 1px solid #858585;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
}

.uplift.client-project-list table tr:nth-child(even) td {
  background-color: #eee;
}

.uplift.client-project-list table tr:nth-child(even) td.is-warning {
  color: #000;
  /* font-weight: bold; */
}

.uplift.client-project-list table tr:nth-child(even) td.is-warning a span {
  color: #000;
  font-weight: normal !important;
}

#root .uplift.client-project-list table {
  border: 0;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.briefing-scroll-list-for-proj-details {
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}

.briefing-scroll-list-for-proj-details .project-details-brief-card {
  box-shadow: none;
  padding: 0 16px;
}

.briefing-scroll-list-for-proj-details
  .project-details-brief-card
  .card-header-title {
  font-weight: 600;
  font-size: 16px;
}

.briefing-scroll-list-for-proj-details
  .project-details-brief-card
  .public-DraftEditor-content {
  min-height: auto;
}

#root table.table-notification-list .is-new {
  background-color: #fefefe;
}

#root table.table-notification-list .is-new .is-new-label {
  background-color: #000;
  color: #fff;
  font-size: 75%;
  margin-right: 6px;
  padding: 4px 9px 3px;
}

.uplift.client-project-list thead th .table-icon {
  background: no-repeat center center;
  background-size: contain;
  height: 22px;
  margin: 8px 0;
}

.uplift.client-project-list thead th .table-icon img {
  margin-bottom: 10px;
}

.uplift.client-project-list .container-table {
  overflow: visible;
  margin: 24px 18px 0;
}

.uplift .bp__control .bp__multi-value {
  background-color: #000;
  color: #fff;
  position: relative;
  top: -4px;
  border-radius: 5px;
}

.uplift .bp__control .bp__multi-value__label {
  color: #fff;
}

.uplift.new-project-thankyou-image a,
.uplift.new-project-thankyou-image a:visited,
.uplift.new-project-thankyou-image a:hover,
.uplift.new-project-thankyou-image a:active {
  color: black;
  text-decoration: underline;
}

.uplift.new-project-thankyou-image {
  background-color: #fff;
  padding-top: 64px !important;
}

.uplift.new-project-thankyou-image .colot-line {
  background-color: #000;
  height: 8px;
  margin-top: 8px;
  margin-bottom: 18px;
}

.navbar-link-name-not-mobile {
  display: none;
}

/* .navbar-item.has-dropdown.is-hoverable {
    display: none;
} */

/* @media (max-width: 768px) {
    .hero-head .navbar-end .navbar-item {
        display: inline-block;
        float: right;
        height: 43px !important;
        width: 49%;
    }
} */

@media (max-width: 768px) {
  .uplift.client-step-1 .uplift-step-1 {
    padding-top: 40px;
  }

  #root .uplift-hero-3 {
    padding-top: 44px !important;
  }

  .uplift.client-step-1 .uplift-step-3,
  .uplift.client-step-1 .uplift-step-4 {
    background-color: #fff;
  }
  .uplift-notification-list-container {
    padding: 0 18px;
  }
}

.uplift .flag-yellow,
.uplift .flag-blue,
.uplift .flag-purple,
.uplift .flag-green,
.uplift .flag-red,
.project-definition .flag-yellow,
.project-definition .flag-blue,
.project-definition .flag-purple,
.project-definition .flag-green,
.project-definition .flag-red {
  display: inline-block;
  padding: 0 12px;
}

.uplift .flag-yellow,
.project-definition .flag-yellow {
  background-color: #fff291;
}

.uplift .flag-blue,
.project-definition .flag-blue {
  background-color: #a8d7eb;
}

.uplift .flag-purple,
.project-definition .flag-purple {
  background-color: #bab1d1;
}

.uplift .flag-green,
.project-definition .flag-green {
  background-color: #e3fbb8;
}

.uplift .flag-red,
.project-definition .flag-red {
  background-color: #ffc5b9;
}

.uplift .invitation-tabs {
  border-bottom: solid 3px #1883ff;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 16px;
  margin-right: 18px;
}

.uplift .invitation-tabs .react-tabs__tab--selected {
  background-color: #1883ff;
  border: 0;
  border-radius: 0;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.uplift .invitation-tabs .react-tabs__tab {
  padding: 12px 22px;
}

.card-boxes .card-intro-icon,
.card-box .card-intro-icon {
  padding: 24px 0 0 24px;
}

.uplift .box.box-with-cover {
  border-radius: 0;
  display: flex;
  flex-direction: row;
  padding: 0;
  position: relative;
}

.uplift .invitation-list-item-details .button.is-black {
  border-radius: 16px;
  font-weight: 600;
  padding: 7px 18px;
  text-transform: uppercase;
}

.uplift .invitation-list-item-details {
  padding: 12px 0 24px 24px;
}

.uplift .box-with-cover .tag {
  transform: scale(0.75);
  position: absolute;
  top: 8px;
  right: 2px;
}

.uplift.partner-projects h1,
.uplift.client-project-list h1,
.uplift.partner-projects h1 + p,
.uplift.client-project-list h1 + p {
  padding: 0 24px;
}

.uplift.partner-projects h1 + p,
.uplift.client-project-list h1 + p {
  padding-bottom: 24px;
}

@media (min-width: 768px) {
  .card-box .card-row .card {
    display: flex;
    flex: 1;
    min-height: 120px;
    width: 100%;
  }

  .uplift-fullheight-bg .new-project-page-bg-image {
    min-height: 100vh;
  }

  .card-boxes .card {
    display: flex;
    flex: 1;
    min-height: 120px;
  }

  .card-boxes .card:nth-child(even) {
    margin-right: 0;
  }
  .card-boxes,
  .card-box {
    display: flex;
    flex-direction: column;
  }

  .card-boxes {
    background: #fff;
    border-radius: 20px;
  }

  .card-box .card-row {
    display: flex;
    flex-direction: row;
  }
  .uplift.partner-projects h1,
  .uplift.client-project-list h1,
  .uplift.partner-projects h1 + p,
  .uplift.client-project-list h1 + p {
    padding: 0;
  }
  .uplift .box-with-cover .tag {
    transform: scale(1);
    top: 18px;
    right: 16px;
  }
  .uplift.client-project-list .container-table {
    margin: 0;
  }
  .uplift .invitation-tabs {
    margin-bottom: 24px;
    margin-left: 0;
    margin-right: 0;
  }
  .uplift.client-step-1 {
    background-color: #f0e2a1;
  }
  .navbar-link-name-not-mobile {
    display: inline-block;
  }
  .navbar-item.has-dropdown.is-hoverable {
    display: block;
  }
  .uplift.new-project-page-custom-bg {
    padding-top: 148px;
  }

  .uplift.new-project-thankyou-image {
    background-repeat: no-repeat;
    background-size: 40% 50%;
    padding-top: 128px !important;
  }

  .new-project-page-bg-image {
    background-image: url('/img/bg-banner.svg');
    background-repeat: repeat-y;
    background-size: 40% 60%;
  }

  .uplift .title-for-np {
    padding-top: 64px;
  }

  .uplift .text {
    font-size: 85%;
    line-height: 1.4;
    padding-bottom: 24px;
  }

  .uplift .np-basicFormInit .field {
    width: 100%;
  }

  .uplift.review-bg {
    background: linear-gradient(
      to right,
      #fff 0%,
      #fff 55%,
      #f6f6f6 55.000001%,
      #f6f6f6 100%
    );
  }

  /* .uplift .form-group {
        border-left: solid 8px #BAB1D1;
        padding-left: 28px;
    } */

  .uplift .opening-cover {
    background-color: #dedede;
    background-size: cover;
    margin-top: 140px;
    position: relative;
    width: 100%;
    height: 220px;
  }

  .uplift .opening-cover + .container {
    padding-top: 96px;
  }

  .uplift .opening-cover-logo {
    background-color: #999;
    background-size: cover;
    border-radius: 50%;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 12.5%;
    top: 96px;
    width: 180px;
    height: 180px;
  }

  .invitation-list-item-image-cover {
    background: #dedede no-repeat center center;
    background-size: cover;
    min-height: 220px;
    display: flex;
    width: 280px;
  }

  .invitation-list-item-details {
    flex: 1;
    padding: 16px 36px;
  }
}

.uplift.full-review-bg {
  background-color: #f7f7f7;
}

.uplift.full-review-bg .media-content .level-left .title::after,
.project-definition .media-content .level-left .title::after {
  background-color: #fff291 !important;
}

.notificationPagination {
  padding: 12px 0 36px;
  text-align: center;
}

.notificationPagination .button.is-black:focus:not(:active),
.notificationPagination .button.is-black.is-focused:not(:active) {
  box-shadow: none;
  outline: none;
}

.projAssetBundleBullet {
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  padding: 4px 9px;
  margin: 0 12px;
  transform: scale(1, 0.85);
}

.project-definition .project-details-review .uplift .card,
.project-ongoing .project-ongoing-review .uplift .card {
  display: flex;
}

.delivered-alert {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
}

.delivered-alert.yes-delivered i {
  color: #007800;
}

.delivered-alert.no-delivered i {
  color: #ed6e59;
}

.timeline-item-first .delivered-alert {
  right: 0;
  left: inherit;
}

.timeline-name {
  position: relative;
}

.tooltip-bp-content {
  padding: 15px 5px;
  min-width: 200px;
}

.tooltip-bp-content h4,
.tooltip-bp-content p,
.tooltip-bp-content li,
.tooltip-bp-content li strong {
  color: #fff;
  text-align: left;
}

.tooltip-bp-content ul {
  margin: 0;
}

.tooltip-bp-content li {
  list-style: none;
  line-height: 16px;
  margin-bottom: 20px;
}

.tooltip-bp-content li span {
  display: block;
}

.link-disable {
  pointer-events: none;
  opacity: 0.3;
}

.ul-list-errors {
  background: #ffebe8;
  border: dotted #ccc 2px;
  padding: 15px;
  margin-bottom: 20px;
}

.ul-list-errors li {
  margin-bottom: 10px;
}

.msg-accepted {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.msg-accepted.yes-accepted {
  color: #48bb78;
  font-size: 125%;
  line-height: 1.25;
}

.msg-accepted.no-accepted {
  color: #e53e3e;
  font-size: 120%;
}

.msg-accepted h4 {
  font-weight: bold;
  font-size: 18px;
}

.msg-info {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.msg-info h4 {
  font-weight: bold;
  font-size: 120%;
}

.content-tip-reason {
  padding: 20px 10px;
}

.content-tip-reason.content-parceiro {
  max-width: 300px;
}
.content-tip-reason h4 {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  color: #fff;
  border-bottom: solid 1px #fff;
  text-align: left;
}

.content-tip-reason ul li,
.content-tip-reason p,
.content-tip-reason strong {
  text-align: left;
  color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 12px;
}

.content-tip-reason div {
  text-align: left;
  color: #fff;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.icon-reasonso i {
  float: right;
  position: relative;
  right: 30px;
}

.icon-reasonso.ok i {
  color: #2f7709;
}

.icon-reasonso.not i {
  color: #f40c0c;
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.info-admin-icon {
  margin-left: 15px;
}

.info-invite-icon {
  margin-right: 15px;
}

.info-admin-icon .fas.fa-question-circle::after {
  display: none;
}

html,
body {
  height: auto;
  width: auto;
}

.msgOk {
  border: dotted 1px #ccc;
  background: #e3fbb8;
  padding: 15px;
  margin-bottom: 20px;
}

.btn-right a {
  float: right;
}

.swal-button {
  transition: background 0.3s ease;
}
.swal-button--confirm {
  background-color: #ffdd57;
  color: #000;
}

.swal-button--confirm:hover {
  background-color: #000;
  color: #fff;
}

.swal-button:not([disabled]):hover {
  background-color: #000;
  color: #fff;
}

.is-selected {
  border: solid 1px #000;
}

html,
body {
  height: auto;
  width: auto !important;
}

.hero-body.page-whatif {
  display: block !important;
  padding: 0 !important;
}

button::after {
  animation-duration: 0s !important;
}
.disabledLink {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.45;
}

.card-container {
  margin-top: 80px;
}

.card-container .ant-tabs {
  overflow: inherit;
}

.card-container .slide2 {
  top: -2px;
}

.card-container .slide3 {
  top: -4px;
}

.card-container .slide4 {
  top: -6px;
}

.card-container .slide5 {
  top: -8px;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -17px;
}
.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  border-radius: 20px;
  border-top-left-radius: 0;
  padding: 20px;
  position: relative;
  background: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border: 0;
  background: #636363;
  color: #fff;
  font-weight: bold;
  border-radius: 0;
  -webkit-border-top-left-radius: 12px;
  -webkit-border-top-right-radius: 12px;
  -moz-border-radius-topleft: 12px;
  -moz-border-radius-topright: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  line-height: 45px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border: 0;
  background: #000;
}

.field-disabled {
  opacity: 0.3;
}

.field-disabled input,
.field-disabled label {
  cursor: not-allowed;
}

.partner-step .container .subtitle.no-margin {
  margin-bottom: 0px;
}

@media (max-width: 375px) {
  #navbarMenu .navbar-dropdown.navbar-notification-window,
  #navbarMenu .navbar-item.is-active .navbar-dropdown {
    width: 100%;
    top: 74px;
    position: fixed !important;
    max-width: auto;
  }
}

.carousel .image.is-4by3 img {
  position: relative;
  display: inline;
  max-height: 350px;
  width: auto;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
}

.carousel .image.is-4by3 {
  padding-top: 0;
  text-align: center;
  height: 350px;
}

.preview-cards-not-media .carousel-item figure {
  background: #f5f5f5;
}

.FormatLabel {
  font-weight: normal !important;
}

.btnWhatsapp {
  margin-top: 30px;
}

.btnWhatsapp i {
  border-radius: 20px;
}

.btnWhatsapp a,
.btnWhatsapp i {
  display: inline-block;
  padding: 6px 18px;
  overflow: hidden;
  font-style: normal;
}

.btnWhatsapp a span,
.btnWhatsapp i span {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-top: -2px;
  float: left;
}

.btnWhatsapp img {
  width: 20px;
  position: relative;
  top: 6px;
  margin-right: 10px;
  float: left;
}

.hcard {
  padding: 15px;
}

.hcard p {
  font-size: 12px;
}
.hcard strong,
.hcard b {
  display: block;
}

.hcard span {
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  border: dotted 1px #ececec;
}

.container.thankyou {
  padding-top: 76px;
}

.checkBorder {
  border: solid 1px #000;
}

.table-main {
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 720px) {
  .navbar-menu.admin-menu-scrool {
    width: 100%;
    overflow-x: auto;
  }
}

@media (max-width: 480px) {
  .campo-table-mob {
    display: block;
    float: none;
    margin-bottom: 15px !important;
  }

  .field.is-grouped.table-mob {
    display: block;
  }

  .no-mob {
    display: none !important;
  }
}

.nav-sub {
  position: relative !important;
  padding: 0 20px;
}

.nav-sub a {
  height: 100%;
}

.sumenu-admin {
  position: absolute;
  top: 53px;
  border: solid 1px #ccc;
  background: #fff;
  left: 20px;
  width: 120px;
}

.sumenu-admin .sumenu-admin {
  left: inherit;
  left: 118px;
}
.steps .step-item .step-details .step-title {
  font-size: 16px !important;
  line-height: 20px;
}

.steps .step-item .step-details p {
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
}

.steps .step-item.is-active .step-marker {
  background-color: #00d1b2;
  border-color: #00d1b2;
}

.steps .step-item.is-completed .step-marker {
  background-color: #9ee5d8;
}

.steps .step-item.bp-is-pending .step-marker {
  background-color: #d3d3d3;
}

.steps .step-item.bp-is-pending .step-details p {
  opacity: 0.3;
}

.btn-open-avaliacao {
  float: right;
}

.pbtn-avaliacao {
  overflow: hidden;
  margin-bottom: 30px;
  float: right;
  position: relative;
  top: -50px;
  z-index: 80;
}

.item-rating .title-item {
  font-weight: bold;
  margin-bottom: 0px;
}

.item-rating {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: dotted 1px #ccc;
}

.animate-loading {
  text-align: center;
}

.animate-loading i {
  font-size: 40px;
}
/* .steps .step-item .step-marker {
    background-color: #00d1b2;
    border-color: #fff;
} */

.loadingAssets {
  text-align: center;
  margin-top: 50px;
}

.loadingAssets i {
  font-size: 40px;
  opacity: 0.7;
}

.parceiro-projeto-margin {
  margin-top: 120px;
}

.ProjectOk {
  padding-top: 180px;
  text-align: center;
}

.errorsli {
  padding: 30px 0 0 120px;
}

.errorsli li {
  margin-bottom: 5px;
  list-style: disc !important;
  color: #e32f02;
  font-size: 14px;
}

.TabBtn {
  float: right;
  margin-left: 2rem;
}

.TabBtn i {
  margin-left: 10px;
  margin-top: 5px;
  font-size: 22px;
  color: #818181;
}
.card .image.imageFix {
  text-align: center;
  height: 180px;
}

.card .image.imageFix img {
  display: inline-block;
  height: 100%;
  width: auto;
}

.spaceCheck {
  margin: -50px 0 -30px !important;
}

.BoxcheckBorder .checkmark,
.checkBorderLine .checkmark {
  border: solid 1px;
}

.for-np + div .BoxcheckBorder .checkbox {
  margin-bottom: 82px;
}

.content-terms li {
  font-size: 15px;
  color: #333333;
  line-height: 24px;
}

.pbtn-down {
  margin-top: 20px;
  text-align: right;
  padding: 0 10px;
}

.orFields {
  padding-bottom: 60px;
}

.orFields h4 {
  padding-bottom: 15px;
  margin-bottom: 50px;
  border-bottom: solid 1px #eeeeee;
}

.btnMim .control {
  text-align: center;
  margin-top: 35px;
}

.contentOr {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.textOr {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.textOr p {
  margin-top: 40px;
}

.errorTEP {
  border: solid 1px #ff3860;
  display: block;
  width: 100%;
  padding: 20px 20px 10px;
  background: #fcf7f7;
  margin-top: 30px;
}

.errorTEP ul {
  list-style: disc;
  padding-left: 20px;
}

.errorTEP ul li {
  margin-bottom: 10px;
}

.pright {
  text-align: left;
}

.boxDelegate {
  background: #ddf9ff;
  padding: 20px;
  margin-top: 20px;
}

.boxDelegate h3 {
  padding: 0 0 10px;
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;
}

.priceStrong {
  float: right;
}

.priceStrongDollar {
  float: right;
  font-weight: normal;
  font-size: smaller;
  margin-right: 10px;
  margin-top: 1px;
}

.priceTotalDollar {
  font-weight: normal;
  font-size: 10px;
  margin-left: 5px;
}

.field .is-white {
  background: #fff;
  border: solid 1px #000;
}

.field .is-white:hover {
  background: #000;
  color: #fff;
  border: solid 1px #000;
}

.checkbox .checkmark {
  border: solid 1px #000;
}

.ant-btn.btnDangerList {
  padding: 0 8px;
  height: 28px;
  line-height: 30px;
  margin-left: 10px;
}

.btnEditarAction {
  width: calc(100% - 44px);
}

.ant-notification {
  z-index: 9999999999999;
}

.forceRight {
  margin-right: 20px;
}

.btAssetsCreate {
  border-radius: 20px;
  margin-top: 130px;
  background: #fff;
  padding: 40px 20px;
  text-align: center;
}

.btAssetsCreate p {
  margin-bottom: 20px;
}

.btAssetsCreate button {
  border-radius: 18px !important;
  border-radius: 18px;
  letter-spacing: 3px;
  height: auto;
  font-size: 12px;
  padding: 8px 30px !important;
  margin-top: 0;
  font-weight: bold;
}

.btAssetsCreate button a {
  color: #fff;
}

.customized-projects-body .customize-cart-dropdown-updated .button.is-black {
  font-weight: bold;
}

.boxRedefinir {
  text-align: left;
}

.card-type {
  padding: 20px;
  margin-bottom: 20px;
}

.resume-cart-content table {
  background: #fff;
}

.form-resumo {
  padding-top: 120px;
}

.form-resumo-client {
  padding-top: 40px;
}

.hrNew {
  margin: 10px 0 !important;
}

.space-padding {
  padding: 0 0 0 20px;
}

.TopCard {
  margin-top: 79px;
}
.top-assets-partner {
  padding-top: 120px;
}

.hero-head.is-fixed + div > .hero-body.new-sub {
  padding-bottom: 0 !important;
}

.new-sub .title {
  line-height: 50px;
}
.RowAssetMargin {
  margin-top: 35px;
}

input:read-only {
  background-color: #f5f5f5;
  border: none !important;
}

.ulErrors {
  border: solid 1px #fd1313;
  background: #fcebe8;
  padding: 20px 20px;
}

.ulErrors li {
  margin-bottom: 10px;
  font-size: 14px;
}

.ulErrors li i {
  margin-right: 5px;
  color: #fd1313;
}

.textAreaCounter {
  margin-bottom: 0;
}

.labelClean .ant-form-item-label > label::after {
  display: none;
}

.BtnAction {
  margin-bottom: 20px;
  text-align: right;
}
.descriptionPage {
  margin-bottom: 35px;
  display: block;
  padding: 20px;
  background: #f9f9f9;
  border-bottom: 5px solid #f7f7f7;
  font-size: 14px;
}

.descriptionPageMine {
  margin-bottom: 20px;
}

.iconLabel {
  margin-left: 5px;
}

.helpText {
  font-size: 12px;
}

.textHelpSection {
  font-size: 12px;
  margin-bottom: 30px;
}

.termsText p {
  margin-top: 12px;
}

.termsText {
  padding-right: 25px;
}

.containerTerms {
  max-height: 350px;
  overflow: auto;
}
.boldBlack {
  font-size: 16px;
  color: #000;
}

.crop-container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
  border: solid;
  width: 600px;
  height: 600px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.sectionError {
  padding: 10px 0;
}
.sectionError li a {
  text-decoration: underline;
}
.sectionError h3 {
  margin-bottom: 10px;
  font-size: 16px;
  color: #000;
}

.sectionError h3 a {
  color: #000;
}

.boxUserCampo {
  background: #fff;
  margin-right: 5px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  padding: 20px;
  margin-bottom: 30px;
}

.boxUserCampo p {
  font-weight: bold;
  margin-bottom: 15px;
}

.boxUserCampoResume {
  max-width: 400px;
}

.headerX {
  position: fixed;
  width: 100%;
  z-index: 45;
  top: 57px;
}

.headerX .title {
  margin-top: 15px;
}

.helptext {
  font-size: 12px;
}

.boxFiles {
  background: #fff;
  padding: 30px 0;
  box-sizing: border-box;
}
.itemFile {
  background: #fff;
  border: 5px solid #eeeeee !important;
}

.itemFile.line {
  background: #fff;
  border: 0 !important;
  border-bottom: 1px solid #ccc !important;
}

.itemFile.grey3 {
  background: #fff;
  border: 0 !important;
  border-top: 5px solid rgb(250, 250, 250) !important;
  border-bottom: 5px solid rgb(250, 250, 250) !important;
}

.itemFile.grey {
  background: #fff;
  border: 0 !important;
  border-top: 5px solid #f7f7f7 !important;
  border-bottom: 5px solid #f7f7f7 !important;
}

.itemFile.grey2 {
  background: #fff;
  border: 0 !important;
  border-top: 5px solid #eeeeee !important;
  border-bottom: 5px solid #eeeeee !important;
}

.itemFile a {
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
}

.itemFile a:hover {
  color: #4b4444;
}

.list-documentos {
  margin-top: 30px;
}

.list-documentos p {
  border-bottom: solid 1px #ccc;
  padding-bottom: 10px;
}

.list-documentos p i {
  margin-right: 8px;
}

.list-documentos p a {
  color: #000;
}

.list-documentos p a:hover {
  color: #4b4444;
}

.miniLink {
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  display: block;
  color: #000;
  text-overflow: ellipsis;
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
}

.listIdeia {
  border-bottom: solid 1px #999;
  padding: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.listIdeia a {
  color: #000;
  transition: all 0.5s ease;
}

.listIdeia a:hover {
  color: #707070;
}

.listIdeia .delete {
  float: right;
  position: relative;
  top: -4px;
}

.listIdeia a span {
  width: 90%;
}

.listIdeia a span i {
  margin-right: 5px;
}

.textInvite {
  padding: 15px;
  background: #f9f9f9;
}

.slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btnDown a {
  font-weight: normal !important;
}
#partner-signup-steps li a.progress-bar--menu-item span {
  background: #434343 !important;
}

.tag:not(body).is-warning {
  background: #434343 !important;
  color: #fff !important;
}

.fas.fa-question-circle::after {
  display: none !important;
}
/** Bravi  customization **/

.vline {
  border-left: 2px solid #707070;
  height: 95%;
}

/* buttons' icons */

button.card-svg-icon {
  border-radius: 50%;
  height: 36px;
  cursor: pointer;
  padding: 0;
  border: 0;
  margin: 0;
}

button.card-svg-icon,
button.card-svg-icon > img {
  width: 36px;
}

/* partner scheduling */

.is-flex-center {
  display: flex;
  align-items: center;
}

.partnersApproval .card {
  box-shadow: none;
  border: 1px solid #707070;
  border-radius: 20px;
}

/* .client-step {
    background-color: white;
} */

.partnersApproval .title-1 {
  font-weight: bold;
}

.partnersApproval {
  line-height: 25px;
  font-size: 15px;
}

.partner-logo {
  border-radius: 50%;
}

.button.grey {
  background-color: #434343;
  color: white;
  border-radius: 40px;
  /* font-size:13px; */
  /* line-height: 23px; */
  height: 35px;
  /* max-width: 120px; */
  /* width: 100%; */
}

.button.grey,
.button.blue {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.button.blue,
.button.blue.large {
  background-color: #1883ff;
  color: white;
  border-radius: 40px;
  font-size: 13px;
  line-height: 23px;
  height: 35px;
  width: 100%;
}

.button.blue {
  max-width: 162px;
}

.no-webkit {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

a.no-webkit {
  color: inherit;
}

.button.blue.large {
  max-width: 180px;
}

.partner-select {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 367px;
}

.custom-timepicker {
  width: 100%;
  max-width: 110px;
  height: 40px;
}

.custom-timepicker input {
  border: none !important;
  line-height: 40px;
  height: 40px;
  font-size: 30px;
}

.custom-timepicker .ant-time-picker-icon {
  display: none !important;
}

.ant-time-picker-panel {
  z-index: 999999999 !important;
}

/* Schedule list page */

button.filter {
  border-radius: 7px;
  font-size: 13px;
  line-height: 20px;
  height: 36px;
  min-width: 117px;
  margin-top: 5px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
}

button.filter.light-gray {
  background-color: #eeeeee;
  color: #434343;
}

button.filter.dark-gray {
  background-color: #434343;
  color: #ffffff;
}

button.date {
  background-color: #ffffff;
  color: #434343;
  border: 1px solid #434343 !important;
  font-size: 13px;
  line-height: 20px;
  height: 39px;
  min-width: 270px;
  margin-top: 5px;
  border: none;
  cursor: pointer;
}

button.date.disabled {
  background-color: #eeeeee;
  cursor: default;
}

button.filter.is-active,
button.date.is-active {
  background-color: #1883ff;
  color: #ffffff;
}

.card.schedule {
  border: 1px solid #707070;
  min-height: 450px;
  min-width: 333px;
}

.card.schedule.invite {
  width: 25%;
  margin-right: 5%;
}

.card.schedule.invite .card-content {
  height: 100%;
}

.card.schedule .card-content,
.card.schedule.mini .card-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  width: 100%;
}

.card.schedule.mini {
  border: none;
  min-height: 354px;
  min-width: 270px;
  padding-top: 115px;
  background-color: #eeeeee;
}

.card.schedule,
.card.schedule.mini {
  margin-top: 10px;
  margin-bottom: 10px;

  border-radius: 15px;
}

.card.schedule.mini .card-banner {
  position: absolute;
  top: 0;
  left: 0;

  border-radius: 15px 15px 0 0;
  width: 270px;
  height: 78px;

  border: none;
}
.card.schedule.mini .card-banner .card-logo {
  position: absolute;
  bottom: -35px;
  left: 20px;
  border-radius: 50%;
  width: 72px;
  height: 71px;
}

.card.schedule.mini .card-info {
  flex-flow: column nowrap;
  font-size: 13px;
}

.card.schedule.mini .card-info span {
  line-height: 21px;
  color: #434343;
}

.card.schedule.mini .card-info .card-title {
  line-height: 20px;

  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.card.schedule.scroll-overflow .card-content {
  overflow: auto;
  max-height: 450px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.card.schedule.scroll-overflow .card-content::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.card.schedule .card-content .scroll-overflow {
  overflow: auto;
  max-height: 250px;
  display: block;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.card.schedule .card-content::-webkit-scrollbar .scroll-overflow {
  display: none; /* Chrome, Safari and Opera */
}

.list-documentos {
  margin-top: 30px;
}

.list-documentos p {
  border-bottom: solid 1px #ccc;
  padding-bottom: 10px;
}

.list-documentos p i {
  margin-right: 8px;
}

.list-documentos p a {
  color: #000;
}

.list-documentos p a:hover {
  color: #4b4444;
}

.miniLink {
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  display: block;
  color: #000;
  text-overflow: ellipsis;
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
}

.listIdeia {
  border-bottom: solid 1px #999;
  padding: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.listIdeia a {
  color: #000;
  transition: all 0.5s ease;
}

.listIdeia a:hover {
  color: #707070;
}

.listIdeia .delete {
  float: right;
  position: relative;
  top: -4px;
}

.listIdeia a span {
  width: 90%;
}

.listIdeia a span i {
  margin-right: 5px;
}

.textInvite {
  padding: 15px;
  background: #f9f9f9;
}

.slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* simple modal */

.modal.is-round .modal-content {
  border-radius: 10px !important;
}

.modal.overflow .modal-content {
  overflow: visible;
}

.inside-modal-content,
.outside-modal-naked {
  background-color: #ffffff;
  border-radius: 20px;
  position: absolute;
  right: 0;
  top: -40px;
}

.outside-modal-content {
  display: none;
  background-color: #ffffff;
  border-radius: 20px;
  position: fixed;
  right: 20px;
  top: 10px;
  z-index: 9999;
}

/* scheduling */

.scheduling-container {
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
}
.sucessoModal {
  padding: 50px;
  text-align: center;
}
.scheduling-container-content {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.link-opcoes {
  font-size: 14px;
  text-align: center;
  color: #000;
  text-decoration: underline;
  display: block;
  line-height: 18px;
  margin: 20px 0 40px;
}

.scheduling-pickers-form {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: #ffffff;
  padding: 2% 20px;
}

.scheduling-partners-form {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  background-color: #eeeeee;
  padding: 2% 5%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
}

.scheduling-partners-form .available-dates ul li span {
  margin-right: 10px;
}

.text-picker {
  font-size: 14px;
  padding: 0 20px 30px;
}
.pickers-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.container-group {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  min-width: 300px;
}

.timepicker-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  font-size: 1.1rem;
}

.partners-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.partners-container .button.blue {
  min-width: 162px;
}

@media (max-width: 780px) {
  .inside-modal-content {
    display: none;
  }
  .outside-modal-content {
    display: block;
  }

  .modal.overflow.simple-modal .modal-content {
    overflow: auto !important;
    padding-top: 40px;
  }
  .scheduling-pickers-form,
  .scheduling-partners-form {
    padding: 2%;
  }
  .scheduling-partners-form .available-dates {
    margin-bottom: 20px;
  }

  .scheduling-partners-form {
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-right: unset;
  }

  .pickers-container {
    flex-flow: column nowrap;
  }

  .partners-container {
    align-items: center;
  }
}

@media (max-width: 870px) {
  .descMini p {
    line-height: 18px !important;
  }
}

@media (max-width: 480px) {
  .btnDown {
    float: inherit !important;
    top: 0 !important;
    margin: 30px 0;
  }
}

.bpitch-optionalStep {
  opacity: 0.45;
}
