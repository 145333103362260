.radiomark{
    background-color: transparent;
    border: solid 1px #909090;
}

.radiomark {
    /* top: 30%; */
}

.radio .radiomark:after {
    top: 6px;
    left: 6px;
}

.payment-beneficiary-item{
    display: inline-block;
    padding: 0.5rem;
}

.payment-beneficiary-new{
    display: inline-block;
    padding: 0.5rem;
    line-height: 50px;
    color: #1883ff;
}

.searchInput .bp__control .bp__value-container{
    top: 0;
}
