.bp-menu-list li .bp-is-active {
  border-left: solid 10px #000;
}

.bp-menu-list li .bp-is-inactive {
  border-left: solid 10px #eeeeee;
}

.bp-menu-list li {
  line-height: 2rem;
}

.bp-menu-list li a {
  text-decoration: none;
  color: #353535;
  font-weight: bold;
  font-size: 80%;
  display: inline-block;
  padding-left: 1rem;
}

.bp-event-subtitle {
  padding-left: 3rem;
  margin-bottom: 2rem;
}

/* .bp-event-section {
    padding-left: 2rem;
    border-left: solid 10px #000;
} */

.bp-event-anchor {
  float: right;
}

.bp-event-section .checkmark {
  outline: solid 1px #000;
  border: none;
}

.bp-event-section .checkbox input:checked ~ .checkmark {
  background-color: #000 !important;
}

.bp-event-section .radiomark {
  border: solid 1px #000 !important;
}

.bp-event-section .radio .radiomark:after {
  background-color: #000 !important;
}

.bp-event-section .input[disabled],
.bp-event-section .textarea[disabled] {
  background-color: #fafafa;
  box-shadow: none;
  color: #7a7a7a;
  border: none !important;
}
