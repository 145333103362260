
.customized-projects-header .is-6,.customized-projects-header .is-9, .customized-projects-header .is-3 {
    background-color: #007DBB;
}

.customized-projects-header .is-3 h4, .customized-projects-header .is-3 div {
    display: none;
}

.customized-projects-header .is-6 .subtitle, .customized-projects-header .is-9 .subtitle, .customized-projects-header .is-6 .regular-control-text, .customized-projects-header .is-9 .regular-control-text {
	color: #FFFFFF;
}

.customized-projects-body .cartResume {
    border-top: solid 8px #007DBB;
}

.customize-baseline{
   background: #007DBB;
}

.customAssetHeader {
	color: #FFFFFF;
    background-color: #007DBB;
}

.complexBadgeTxt{
	color: #007DBB !important;
}

.complexBadge {
	color: #FFFFFF;
    background-color: #007DBB;
}

#partner-signup-steps.partner-signup-newproj-steps .is-active a.progress-bar--menu-item > span {
    opacity: 1;
    background: #007DBB;
}


.customized-projects-header .blue .boxTitle.right {
    padding-left: 40px;
    border-left: solid 1px #23a4e0;
}
