.prop {
    margin-top: 0.5rem;
    font-size: 80%;
}

.prop-group {
    margin-top: 1.5rem;
    text-decoration: underline;
    text-transform: uppercase;
    margin-bottom: 1rem;
}


.prop .name {
    font-weight: bold;
}

.prop .value {
    margin-left: 0.3rem;
}

.prop .value .multi{
    margin-left: 0.25rem;
}

.prop .value .multi:not(:last-child):after {
    content: ', '
}

.prop .value .desc {
    border-bottom: solid 1px #333333;
    text-align: center;
    margin-left: 0.25rem;
}