.partner-contact-item {
    margin-top: 0.1rem;
    padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
}

.partner-contact-item div {
    flex: 1;
}

.partner-contact-item a {
    flex-basis: 1rem;
}

.partner-contact-item:hover{
    background-color: #f5f5f5;
}