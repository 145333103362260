.BoxNewProposal {
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
}

.stepnew .client-container-1 {
  padding-top: 120px !important;
}

.BoxNewProposal .actions {
  margin-top: 30px;
}

.BoxNewProposal .actions a {
  margin-right: 20px;
}

/* RESET BULMA */
.filtersAssetsRate label.ant-radio-wrapper {
  margin-right: 20px;
}

.filtersAssetsRate img {
  margin-left: 10px;
  position: relative;
  top: -3px;
}

.legendAssets {
  margin: 50px 0 30px;
}

.legendAssets.borderTop {
  border-top: solid 4px #fff;
  margin-top: 20px;
  padding-top: 30px;
}

.legendAssets p {
  display: flex;
  margin-bottom: 15px;
  font-size: 12px;
  line-height: 16px;
}

.legendAssets .icone {
  margin-right: 15px;
  width: 32px;
}

.alertIsRequiredAprovation {
  background-color: #000;
  padding: 20px;
  color: #fff;
  border-radius: 15px;
  margin-bottom: 20px;
}

.progress-bar--item.disabled {
  opacity: 0.3;
}

.bolStatus {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: #000;
  border-radius: 12px;
}

.bolStatus.vencer {
  background: #ddd;
}

.bolStatus.vencido {
  background: #f00;
}

.bolStatus.pago {
  background: rgb(7, 197, 0);
}

.legendStatus {
  margin-top: 30px;
}

.listStatus .item {
  margin-right: 25px;
}

.alertFaturamento {
  padding: 30px;
  background: #f3f3f3;
  border-radius: 20px;
  margin-bottom: 40px;
}

.displayValores {
  padding: 20px;
}

.displayValores p {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: solid 1px #ddd;
}

.displayValoresWhite {
  padding: 0;
}

.displayValoresWhite p {
  color: #fff;
  border-bottom: solid 1px #eee;
  margin: 0;
  padding: 10px 0;
}

.msgGlobal {
  padding: 30px;
  border-radius: 20px;
  background: #f7f7f7;
  margin-bottom: 30px;
}

.msgGlobal h2 {
  font-weight: bold;
  margin-bottom: 20px;
}

.BtnEx {
  display: block;
  margin-bottom: 20px;
  position: relative;
  z-index: 80;
  overflow: hidden;
}

.feedback-box {
  padding: 0 40px 50px;
}

.main-feed {
  background: #fafafa;
  padding: 20px;
}

.PbuttonView {
  position: relative;
  top: -40px;
}

.displayDocumento {
  margin: 20px;
  color: #fff;
  background-color: #eeeeee;
  padding: 30px 30px 30px;
  border-radius: 20px;
  text-align: center;
}

.IconeAlert {
  font-size: 40px;
}

.profile-pic {
  width: 45px;
  height: 45px;
  background-color: #007aff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-pic p {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.IconeAlert i {
  color: #ffc222;
}

.displayDocumento h3 {
  font-weight: bold;
  font-size: 24px;
}

.ant-input-number {
  background: transparent;
}

.displayDocumento p {
  margin-bottom: 20px;
}

.messageDocumento p {
  margin: 2px 20px;
  color: #fff;
  background-color: #f8c6c6;
  padding: 15px 15px 15px;
  border-radius: 10px;
  text-align: left;
  color: #000;
}

.BoxPaymentCurrency {
  margin: 0 20px 50px 20px;
  display: block;
  background: #eeeeee;
  border: solid 1px #000;
  border-radius: 15px;
  padding: 20px 40px;
  position: relative;
  top: -20px;
}

.modDoc {
  margin-top: 20px;
  border-top: solid #efefef;
  padding-top: 15px;
  margin-bottom: 30px;
}

.modDoc p {
  font-size: 14px;
}

.boxBeneficiario .radiomark {
  top: 30%;
}

.ant-upload-list.ant-upload-list-text {
  position: relative !important;
  z-index: 90 !important;
}

.modDoc p img {
  width: 20px;
  position: relative;
  left: -4px;
}

.tooltip-status-bpay .ant-tooltip-inner {
  min-width: auto !important;
}

.ant-notification-notice.notification-bpool {
  padding: 16px;
}

.ant-notification-notice.notification-bpool .ant-notification-notice-icon {
  font-size: 30px;
  margin-left: 0;
}

.tooltip-repo-files .ant-tooltip-inner {
  min-width: auto !important;
}

.ant-tooltip-inner {
  min-width: 400px !important;
}

.topRaius {
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  overflow: hidden;
}

.someBox {
  padding: 0 30px 20px;
}

.titPartnerH4 {
  font-size: 16px;
  font-weight: 700;
}

.spanAdmin {
  display: block;
}

.iconColor i {
  color: #fff;
  margin-right: 20px;
}

.pivotTableBp {
  overflow: auto;
}

.mainCalendario {
  padding-bottom: 100px;
  display: block;
  height: 100%;
  min-height: 600px;
}

.boxResumeScroll {
  height: 120px;
  overflow: auto;
  margin: 15px 0;
  padding-right: 15px;
}

.selectIdioma .idioma-bpool:after {
  border-color: #696969 !important;
}

.selectIdioma .idioma-bpool,
.selectIdioma .is-active .idioma-bpool {
  border: solid 2px #e7e7e7;
  background: #e7e7e7 !important;
  padding: 10px 12px 12px;
  display: block;
  box-sizing: border-box;
  height: 35px;
  border-radius: 15px;
  font-family: "Helvetica Neue LT Std";
  color: #696969 !important;
  width: 200px;
}

.selectIdioma .navbar-dropdown {
  width: 200px !important;
  min-width: 200px !important;
}

.selectIdioma .idioma-bpool:hover {
  color: #696969 !important;
  background: #ededed !important;
}

.countBx {
  text-align: right;
  color: #1883ff !important;
  font-weight: bold;
  position: relative;
}

.descMini .spanVat {
  font-size: 10px;
  font-weight: normal !important;
}

.footer-card-select {
  border: 0;
  text-align: center;
  display: block;
  padding-top: 20px;
  max-width: 367px;
}

.sucesso-select {
  max-width: 400px;
  padding-top: 100px;
  margin-bottom: 80px;
}

.complexBadge2 {
  background: #353535;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  margin: 0 2px;
}

.quotation-summary {
  clear: both;
}

.customized-projects-header {
  background: #fff;
  margin-top: -15px !important;
  border-radius: 20px;
}

.customized-projects-header.parHead {
  margin-top: 90px !important;
}

.labelSpan {
  display: inline-block;
  width: 245px;
}

.ant-table-thead>tr>th {
  background: #434343;
}

.ant-table-thead>tr>th * {
  color: #fff;
}

h1 span {
  font-weight: 600;
}

.table td,
.table th {
  vertical-align: middle;
}

.row-bpool .ant-form-item {
  margin-bottom: 10px;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.input,
.textarea {
  box-shadow: none;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
  margin-top: 3px;
}

.ContainerEvent {
  padding: 20px 40px;
}

.bp-menu-list {
  padding-top: 30px;
}

.bp-control-exatra a {
  margin-bottom: 20px;
  margin-right: 20px;
}

/* TITLES */

.topPadding {
  padding-top: 220px !important;
}

.title-bpool {
  font-family: "Helvetica Neue LT Std";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  margin-bottom: 20px;
}

.title-bpool.medium {
  font-size: 25px;
}

.title-bpool.regular {
  font-size: 18px;
}

.title-bpool.big {
  font-size: 32px;
}

.title-bpool.no-bottom {
  margin-bottom: 0;
}

.tagNameExtra {
  margin-bottom: 30px;
}

.pbottom {
  margin-bottom: 10px;
}

.pbottom button {
  cursor: default !important;
}

.pCompany {
  display: block;
  font-size: 12px;
  position: relative;
  font-weight: normal;
  top: -5px;
  color: #333333;
}

.invite .titPartnerH4 {
  margin-top: 5px;
  line-height: 20px;
}

/* Botões */

.price-bpool {
  color: #1883ff;
  font-size: 22px;
  font-weight: 600;
}

.mright {
  margin-right: 10px;
}

/* .ant-btn {
  border-radius: 15px;
  height: 35px;
  color: #000;
  transition: all 0.5s ease;
  padding: 0 20px 0;
  line-height: 39px;
  cursor: pointer;
  display: inline-block;
} */
.itemNone {
  display: none !important;
}

.ant-drawer-mask {
  z-index: 99999;
}

.ant-drawer-content-wrapper {
  z-index: 99999;
}

.ant-btn.button.bt-bpool {
  color: #fff !important;
}

.button.bt-bpool {
  border-radius: 15px;
  border: 0;
  height: 35px;
  color: #fff;
  transition: all 0.5s ease;
  padding: 0 20px 0;
  line-height: 40px;
  background: #1883ff;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
}

.button.bt-bpool.is-selected {
  background: #1883ff !important;
}

.button.bt-bpool.full {
  width: 100%;
}

.button.bt-bpool.big {
  border-radius: 20px;
  font-size: 16px;
  padding: 5px 40px;
  height: 46px;
}

.button.bt-bpool.bsmall {
  font-size: 12px;
  font-weight: bold;
  height: 28px;
  line-height: 29px;
}

.button.bt-bpool.quad {
  border-radius: 5px;
}

.button.bt-bpool.mini {
  font-size: 9px;
  line-height: 30px;
  height: 25px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-weight: 600;
}

.ant-btn.button.bt-bpool.mini {
  line-height: 28px;
}

.button.bt-bpool.black {
  background: #434343;
}

.button.bt-bpool.black:hover {
  background: #696969;
}

.button.bt-bpool.grey {
  background: #ececec;
  color: #434343;
}

.button.bt-bpool.grey-blue {
  background: #d4d4d4;
  color: #707070 !important;
}

.button.bt-bpool.grey-blue:hover,
.button.bt-bpool.grey-blue.active {
  background: #1883ff;
  color: #fff !important;
}

.button.bt-bpool.grey:hover {
  background: #696969;
  color: #fff;
}

.button.bt-bpool:hover {
  background: #434343;
}

.txtOito {
  position: relative;
  top: -25px;
}

.txtOito p {
  font-size: 14px;
  line-height: 18px;
}

.bt-bpool.button.next {
  float: right;
}

.bt-bpool.button[disabled] {
  background: #eeeeee;
  color: #434343 !important;
  opacity: 0.5;
  cursor: not-allowed !important;
}

.bt-bpool.button.desabilitado[disabled] {
  background: #eeeeee;
  color: #fff !important;
  background-color: #696969 !important;
  opacity: 0.3;
  cursor: not-allowed !important;
}

.textResume {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.liderSlideMain {}

.liderSlideMain .slick-track {
  display: flex !important;
}

.liderSlideMain .slick-slide {
  height: inherit !important;
  display: flex !important;
}

.liderSlideMain .slick-slide>div {
  height: 100%;
}

.itemLider {
  height: inherit !important;
}

/* TABLE NOTIFICACAO */

.table-bpool #root table.table-bpool,
#root table.table-bpool.no-opacity {
  border: 0;
  width: 100%;
  margin-bottom: 30px;
}

#root table.table-bpool tr {
  border-bottom: solid 1px #ededed;
}

#root table.table-bpool.no-opacity tr td {
  opacity: 1;
}

#root table.table-bpool tr td {
  padding: 10px 15px 5px;
  opacity: 0.6;
}

#root table.table-bpool td.is-new {
  opacity: 1;
}

.notificationItemTopicDate {
  padding-left: 4px;
}

/* ELEMENTOS DE FORM */

.uplift .label,
.help.is-danger {
  padding-left: 15px;
}

.help.is-danger {
  color: #ff0000;
  font-size: 12px;
  line-height: 20px;
  padding-top: 3px;
}

.section-cadastro {
  min-height: 768px;
}

.section-cadastro .field {
  margin-bottom: 15px;
}

.section-cadastro .form-group {
  margin-top: 0;
}

.section-cadastro .columns {
  margin-top: 15px;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  padding: 0 20px;
  border-radius: 20px;
}

.alertPay {
  background: #eee;
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  font-size: 14px;
}

.alertPay a {
  font-weight: bold;
}

.bpForce.mini div {
  height: auto;
}

.bpForce.mini .filds input[type="email"],
.bpForce.mini .filds input[type="text"],
.bpForce.mini .filds input[type="password"],
.bpForce.mini .field input[type="text"],
.bpForce.mini .field input[type="text"],
.bpForce.mini .field input[type="password"] {
  background: #fff !important;
  height: 40px;
}

.bpForce .filds input[type="email"],
.bpForce .filds input[type="text"],
.bpForce .filds input[type="password"],
.bpForce .field input[type="text"],
.bpForce .field input[type="text"],
.bpForce .field input[type="password"] {
  background: #fff !important;
  height: 50px;
}

.filds input[type="email"],
.filds input[type="text"],
.filds input[type="password"],
.field input[type="text"],
.field input[type="text"],
.field input[type="password"],
body .uplift .control .input,
.ant-input-number-input-wrap input,
.ant-input {
  border: solid 2px #e7e7e7;
  background: #e7e7e7 !important;
  /* border:none; */
  width: 100%;
  padding: 15px 12px 12px;
  display: block;
  box-sizing: border-box;
  height: 35px;
  border-radius: 15px;
  font-family: "Helvetica Neue LT Std";
  color: #696969;
}

.pright {
  margin-top: 15px;
}

.ant-select-selection {
  border: 0;
  background: #e7e7e7;
  border-radius: 15px;
  padding: 1px 0px;
}

.ant-modal-title {
  font-weight: 600 !important;
}

.ant-input-group>.ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.ant-input-group-addon {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.field textarea {
  border: 0;
  outline: none;
  background: #e7e7e7;
  border-radius: 15px;
  resize: none;
}

.select:not(.is-multiple):not(.is-loading)::after {
  color: #000;
}

.field select {
  background: #e7e7e7;
  border: solid 2px #e7e7e7;
  /* border:none; */
  width: 100%;
  display: block;
  box-sizing: border-box;
  height: 35px;
  line-height: 33px;
  border-radius: 15px;
  font-family: "Helvetica Neue LT Std";
  color: #696969;
  outline: none;
  border: 0 !important;
  box-shadow: none !important;
}

body .uplift .control .input.is-danger {
  border: solid 2px #ff0000;
}

body .bp__control {
  width: 100%;
  padding: 0 12px 12px;
  background: #e7e7e7;
  border: solid 2px #e7e7e7;
  display: block;
  box-sizing: border-box;
  min-height: 35px;
  border-radius: 15px !important;
  font-family: "Helvetica Neue LT Std";
  color: #696969;
}

body .bp__control .bp__value-container {
  top: -7px;
  min-height: inherit;
}

.uplift .bp__control .bp__multi-value {
  top: 11px !important;
}

body .uplift .control .bp__control {
  background: #e7e7e7;
}

body .bp__control .bp__value-container {
  padding: 0 15px;
  box-sizing: border-box;
}

body .bp__control .bp__indicators,
body .select::before {
  width: 20px;
  position: absolute;
  top: 2px;
  right: 27px;
  background-color: transparent;
}

.multiple-bpool .bp__indicators {
  right: 65px !important;
}

.pagepreview {
  padding-top: 75px !important;
}

body .bp__control .bp__indicators svg {
  fill: #000;
}

.bp--is-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.review-header {
  margin-top: 160px;
}

.review-header .LogoAvatar {
  left: 20px !important;
}

/* Projeto */

.listBundle .tabs.is-toggle li.is-active a {
  background: #1883ff;
  color: #fff;
}

.listBundle .tabs.is-toggle li.is-active a span {
  color: #fff;
}

.listBundle .tabs.is-toggle li {
  margin-right: 10px;
  margin-bottom: 10px;
}

.bt-ico img {
  position: relative;
  top: 6px;
  right: 5px;
}

.listBundle .tabs.is-toggle li a {
  border-radius: 8px;
  font-size: 13px;
  height: 36px;
  padding: 0 20px;
  line-height: 40px;
}

.topAssets {
  text-align: center;
  padding: 20px 0 60px;
}

.topAssets p {
  color: #434343;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  padding-top: 10px;
}

.title-new {
  color: #434343;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 0 !important;
}

.bp-level {
  padding-bottom: 30px;
  overflow: hidden;
}

.bp-level h5 {
  font-family: "Helvetica Neue LT Std";
  color: #434343;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}

.bp-level h5 span.numberTag {
  color: #fff;
  background: #434343;
  padding: 5px 20px 0;
  line-height: 40px;
  display: inline-block;
  border-radius: 20px;
  margin-right: 10px;
}

.bp-level input {
  border-radius: 20px !important;
  width: 200px;
}

.boxItens {
  background: #eee;
  padding: 20px;
  border-radius: 20px;
  margin: 30px 0;
}

.boxItens .bp__control {
  padding-bottom: 0 !important;
}

.boxItens h2 {
  color: #434343;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  font-family: "Helvetica Neue LT Std";
  float: left;
}

.boxItens ul {
  margin: 20px 0 0 0;
}

.priceText {
  overflow: hidden;
  padding: 0 30px;
  color: #434343;
  font-size: 18px;
  font-weight: bold;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  margin-bottom: 0 !important;
}

.priceText span {
  color: #1883ff;
  font-weight: bold;
  position: relative;
  /* top: 5px; */
}

.bigValue {
  float: right;
}

.priceScope span {
  color: #1883ff;
  font-size: 18px;
  font-weight: bold;
  line-height: 37px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: bold;
}

/* CARD BULMA */

.card {
  box-shadow: none;
  border-radius: 15px;
}

.project-review-bp {
  padding-top: 40px;
}

.flag-yellow {
  background: #000 !important;
  color: #fff;
  margin-left: 7px;
}

/* BRIEFING */
.itemBriefingEdit {
  margin-bottom: 30px;
}

.itemBriefingEdit .card {
  overflow: hidden;
  border: solid 1px;
}

.itemBriefingEdit .card .card-header {
  background: #000;
  color: #fff;
  font-weight: bold;
}

.itemBriefingEdit .card .card-header div {
  color: #fff;
  font-weight: bold;
}

.menu-list li {
  border-bottom: solid 1px #ececec;
}

.menu-list li a {
  color: #000;
  padding: 10px;
}

.menu-list li a.active {
  background: whitesmoke;
  font-weight: bold;
}

/* HACK BULMA */

.check-blue .break-word {
  color: #1883ff;
}

.client-step .checkbox input:checked~.checkmark {
  background: #1883ff !important;
  border-color: #1883ff;
}

.ant-table-column-title {
  font-weight: 600;
}

.card-partner-bpool {
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  border: solid 1px #000;
  border-radius: 30px;
}

.card-partner-bpool .bannerMidia .image {
  height: 187px;
  text-align: center;
  background: #000;
}

.card-partner-bpool .bannerMidia .image img {
  height: 100%;
  display: inline-block;
  width: auto;
}

.card-partner-bpool .card-content {
  padding-left: 0;
  padding-right: 0;
}

.card-top,
.smallCardInfo {
  padding-left: 20px;
  padding-right: 20px;
}

.menuIcons {
  display: block;
}

.menuIcons img {
  width: 33px;
}

.menuIcons .clickWhats {
  width: 36px;
}

.menuIcons a {
  margin-right: 10px;
  opacity: 1;
  transition: all 0.5s ease;
}

.menuIcons a:hover {
  opacity: 0.4;
}

.card-top .urlsite {
  color: #1883ff;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: underline;
}

.card-top {
  margin-bottom: 10px !important;
}

.card-top .companyName {
  display: block;
  padding-top: 4px;
  font-size: 14px;
}

.card-top .pcity {
  color: #696969;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.smallCardInfo {
  text-align: center;
  color: #696969;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.card-partner-bpool .media .image.is-64x64 img {
  overflow: hidden;
  border-radius: 50%;
}

.is-warning span {
  font-weight: normal;
}

.boxInvite {
  border-radius: 20px;
  overflow: hidden;
  background: #eeeeee;
  height: "100%";
  display: "flex";
  position: relative;
  padding-bottom: 70px;
}

.infoInvite {
  padding: 70px 30px 20px;
  height: 55%;
}

.tabsInvite {
  margin-bottom: 40px;
}

.tabsInvite .button {
  margin-right: 10px;
  min-width: 100px;
  font-size: 13px;
}

.tabsInvite .button {
  max-width: 120px;
}

.btnCenter {
  width: 100%;
  text-align: left;
  position: absolute;
  bottom: 10px;
  left: 20px;
}

.infoInvite h3 {
  margin-bottom: 20px;
  color: #434343;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.infoInvite p {
  color: #696969;
  font-size: 13px;
  line-height: 20px;
}

.boxInvite .thumbLogo {
  width: 76px;
  height: 76px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: -40px;
  left: 20px;
}

.boxInvite .thumbLogo img {
  width: 100%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.boxInvite .clientHeader {
  background-size: cover;
  width: 100%;
  height: 78px;
  position: relative;
  /* border-top-right-radius: 20px; */
  /* border-top-left-radius: 20px; */
}

.ant-form-item-control {
  line-height: inherit;
}

.fixedChat {
  display: flex !important;
  position: fixed;
  right: 18px;
  bottom: 100px;
  margin-top: -20px;
  z-index: 700;
  height: 65px !important;
  width: 65px;
  border-radius: 100px !important;
  gap: 5px;
}

.fixedChat img {
  width: 16px;
  position: relative;
  left: 0px;
  margin-bottom: 0px;
}

.fixedChat b {
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 12px;
}

.error-display {
  padding: 20px;
  border: solid 1px rgb(255, 191, 191);
  font-size: 14px;
}

.error-display ul li i {
  color: #e50000;
}

.error-list-bpool ul li i {
  color: #e50000;
}

.errors-top {
  padding-top: 30px;
}

.btns-inline .field {
  display: inline-block !important;
  margin: 0 0 0 15px;
}

.btns-inline {
  text-align: right;
  padding-top: 10px !important;
}

.center-alert {}

.alert-bpool {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.alertTop {
  margin-top: 25px !important;
}

.alert-bpool p {
  font-size: 12px;
  line-height: 16px;
  color: #777;
}

.hero-body.header-bpool {
  padding: 1.5rem 0 1.2rem !important;
}

.btn-voltar-preview {
  display: block;
  text-align: center;
  margin: 50px 0;
}

.btn-voltar-preview .button {
  width: 150px;
}

.topFilter {
  margin: 20px 0;
}

.topFilter .field input {
  background: url("/img/ico-lupa.png") no-repeat #fff;
  height: 42px;
  border-radius: 20px;
  border: 1px solid #1883ff;
  padding-left: 80px;
  background-position: 30px 10px;
}

@media screen and (max-width: 1275px) {
  .container {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }
}

@media screen and (max-width: 1275px) {
  .media-template-card {
    margin: 0 0 20px !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 1200px) {

  .ant-drawer-content-wrapper,
  .ant-modal {
    width: 80% !important;
  }
}

@media screen and (max-width: 768px) {
  .button.bt-bpool.full {
    width: auto;
  }

  .WrapperForm {
    padding: 0 !important;
  }

  .lista-assets {
    margin-top: 60px;
  }

  .build-closure .form-resumo {
    padding-top: 90px;
  }

  .mob-container {
    padding-top: 0 !important;
  }

  .criar-asset {
    padding-top: 0 !important;
  }

  .review-header .LogoAvatar {
    left: 0 !important;
  }

  .review-header {
    margin-top: -20px;
    margin-bottom: 60px;
  }
}

.pselect {
  text-align: center;
}

.pselect .button {
  margin: 0 5px;
}

/* ADMIN */

.admin-page .container {
  width: 90% !important;
  max-width: 90% !important;
}

.available-dates ul li {
  border-bottom: solid 1px #ccc;
  padding: 5px 0 7px;
}

.available-dates ul li .tit-list {
  font-size: 12px;
  position: relative;
  top: 2px;
}

.contentModalCall {
  background: #fff;
  padding: 50px 30px;
  text-align: center;
}

.contentModalCall .control {
  text-align: center;
}

.contentModalCall ul {
  margin: 30px 0;
}

.contentModalCall ul li {
  border-bottom: solid 1px #eee;
  margin-bottom: 5px;
}

.content-card-scrool {
  height: 350px;
  overflow-x: auto;
}

.content-card-scrool h3 {
  font-size: 18px;
  font-weight: 600;
}

.label-company {
  font-size: 12px;
  position: relative;
  top: -10px;
}

.data-meeting {
  margin-bottom: 15px;
}

.box-meeting {
  border-bottom: solid 1px #ccc;
  padding-bottom: 15px;
}

.box-meeting h3 {
  font-weight: 600;
  font-size: 16px;
}

.pageChat {
  border: solid 1px #eeeeee;
  height: 500px;
}

.mainChat {
  height: 100% !important;
}

.scrollItens {
  height: 100%;
  border: solid 2px;
}

.icofile {
  border: solid;
  width: 40px;
  height: 37px;
  display: inline-block;
  border-radius: 50%;
  background: #1883ff;
  color: #eeeeee;
  position: relative;
}

.icofile i {
  font-weight: bold;
  position: absolute;
  top: 5px;
  left: 6px;
}

.admin-content-right {
  padding-left: 30px;
  padding-right: 20px;
}

.listButtons a,
.listButtons button {
  margin-right: 20px;
}

@media screen and (max-width: 600px) {

  .ant-drawer-content-wrapper,
  .ant-modal {
    width: 100% !important;
  }
}

@media (max-width: 576px) {
  .form-mobile button {
    top: 0 !important;
  }
}

.p-btns {
  margin-bottom: 100px;
  overflow: hidden;
}

.tabsPartnerInfo {
  margin-bottom: 40px;
}

.tabsPartnerInfo .button {
  margin-right: 10px;
  min-width: 100px;
  font-size: 13px;
}

/* CALENDARIO */

@charset "UTF-8";

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: #999999;
}

.rbc-off-range-bg {
  background: #e6e6e6;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #ddd;
}

.rbc-header+.rbc-header {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-header+.rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-header>a,
.rbc-header>a:active,
.rbc-header>a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  height: 100%;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  background-color: #eaf6ff;
}

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}

.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}

.rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}

.rbc-btn-group>button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-btn-group>button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group>button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group>button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-btn-group>button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.rbc-btn-group button+button {
  margin-left: -1px;
}

.rbc-rtl .rbc-btn-group button+button {
  margin-left: 0;
  margin-right: -1px;
}

.rbc-btn-group+.rbc-btn-group,
.rbc-btn-group+button {
  margin-left: 10px;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.rbc-slot-selecting .rbc-event,
.rbc-slot-selecting .rbc-day-slot .rbc-background-event,
.rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #265985;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #3b99fc;
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;
}

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
}

.rbc-month-view {
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}

.rbc-month-header {
  background: #000 !important;
  color: #fff !important;
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}

.rbc-month-row+.rbc-month-row {
  border-top: 1px solid #ddd;
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}

.rbc-date-cell.rbc-now {
  font-weight: bold;
}

.rbc-date-cell>a,
.rbc-date-cell>a:active,
.rbc-date-cell>a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0 0%;
}

.rbc-day-bg+.rbc-day-bg {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-day-bg+.rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.rbc-overlay>*+* {
  margin-top: 1px;
}

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #ddd;
  border-spacing: 0;
  border-collapse: collapse;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td {
  padding: 5px 10px;
  vertical-align: top;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr+tr {
  border-top: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table thead>tr>th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead>tr>th {
  text-align: right;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  width: 100%;
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}

.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid #265985;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}

.rbc-day-slot .rbc-background-event {
  opacity: 0.75;
}

.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}

.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #ddd;
  z-index: 10;
  margin-right: -1px;
}

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0 px;
}

.rbc-time-header-content+.rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;
}

.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid #ddd;
  min-height: 0;
}

.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}

.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}

.rbc-time-view .rbc-allday-cell+.rbc-allday-cell {
  border-left: 1px solid #ddd;
}

.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}

.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}

.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #ddd;
}

.rbc-time-header>.rbc-row:first-child {
  border-bottom: 1px solid #ddd;
}

.rbc-time-header>.rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-time-header-content>.rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #ddd;
  overflow-y: auto;
  position: relative;
}

.rbc-time-content>.rbc-time-gutter {
  flex: none;
}

.rbc-time-content>*+*>* {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-time-content>*+*>* {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-time-content>.rbc-day-slot {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}

.rbc-header:first-child,
.rbc-header:last-child {
  background: #777 !important;
  color: #fff;
}

.rbc-date-cell a {
  display: block;
  color: #fff !important;
  width: 31px;
  height: 30px;
  float: right;
  background: #ccc;
  border-radius: 30px;
  text-align: center;
  box-sizing: border-box;
  padding: 8px 5px 0;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}

.rbc-now.rbc-current a {
  background: #000;
}

.tableMilestoneFiles .ant-table-placeholder {
  border: 0;
  background: transparent;
  text-indent: -100px;
}

.iconeLoading {
  text-align: center;
  font-size: 30px;
}

.checkMilestone {
  /* text-align: center; */
  font-size: 20px;
  color: #9cc42b;
}

.errorMilestone {
  font-size: 20px;
  color: #f24423;
}

.slide-types {
  background: #fff;
  padding: 40px 0;
  margin-bottom: 40px;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.itemTypeSlide {
  position: relative;
  padding-bottom: 30px;
}

.itemTypeSlide.active .circType {
  background: #1883ff;
}

.itemTypeSlide.active .circType img {
  /* filter: brightness(0.99); */
  filter: brightness(0) invert(1);
}

.slide-types label.checkbox {
  display: block;
  position: absolute;
  /* background: #c0c; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* opacity: 0.4; */
}

.slide-types .slick-prev,
.slide-types .slick-next {
  display: none;
  width: 20px !important;
  height: 36px !important;
  background-size: 100% !important;
}

.circType {
  width: 97px;
  height: 97px;
  background-color: #fafafa;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 30px;
}

.circType .estrategia {
  position: relative;
  top: -5px;
}

.circType .iconCustom {
  width: 38px;
}

.slide-types .break-word {
  position: absolute;
  display: block;
  width: 100%;
  margin: 0;
  left: 0;
  text-align: center;
  bottom: 0px;
  color: #000000;
  opacity: 1;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
}

.circType img {
  display: inline-block;
}

.slide-types .control {
  position: inherit;
}

.slide-types .checkmark {
  height: 1px;
  width: 1px;
  opacity: 0;
}

.painelbriefing .public-DraftEditor-content {
  min-height: 50px;
}

.fileBriefing {
  font-size: 13px;
}

.item-list-notification {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border: none;
  background: transparent;
  text-align: left;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.PrintLogoName {
  display: none;
}

.PrintLogoName img {
  width: 150px !important;
}

.PrintLogoName h2 {
  text-align: right;
}

/* MENU v2 ADMIN */
.ant-dropdown-menu-item>a,
.ant-dropdown-menu-submenu-title>a,
.ant-dropdown-menu-submenu,
.ant-dropdown-menu-submenu-title {
  color: #4a4a4a;
  font-size: 16px;
}

.ant-dropdown-menu-item>a,
.ant-dropdown-menu-submenu-title>a,
.ant-dropdown-menu-submenu-title {
  padding: 12px 18px;
}

.ant-dropdown-menu-item>a>i,
.ant-dropdown-menu-submenu-title>a>i {
  margin-left: 4px;
}

.ant-dropdown-menu-submenu-arrow {
  right: 4px !important;
}

.ant-dropdown-menu-item>a:visited,
.ant-dropdown-menu-submenu-title>a:visited {
  color: #4a4a4a;
}

.ant-dropdown-menu-item>a:hover,
.ant-dropdown-menu-submenu-title>a:hover {
  color: #000000;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f2f2f2;
}

/* breadcrumb */
.breadcrumb.is-medium {
  font-size: 0.875rem;
}
