.quickmeeting-modal {
}

.quickmeeting-modal .ant-modal {
  width: 1244px !important;
}

.quickmeeting-modal .ant-modal .ant-modal-content {
  background-color: #f3f3f3;
}

.quickmeeting-modal .ant-modal .ant-modal-header {
  border: none;
  background-color: #f3f3f3;
  height: 80px;
  padding: 0;
  display: flex;
  align-items: center;
}

.quickmeeting-modal .ant-modal .ant-modal-header .ant-modal-title {
  font-size: 38px;
  height: 38px;
  line-height: 48px;
  padding-left: 60px;
}

.quickmeeting-modal .ant-modal .ant-modal-header .ant-modal-title:after {
  content: '';
  width: 75px;
  height: 7px;
  background: #000;
  position: absolute;
  margin-top: 50px;
  left: 0;
  margin-left: 60px;
}

.quickmeeting-modal .ant-modal .ant-modal-body {
  padding: 10px 60px 20px 60px;
}

.quickmeeting-modal .ant-modal .ant-modal-footer {
  border: none;
  padding: 20px 40px 30px 40px;
}

.quickmeeting-modal .ant-modal .ant-modal-footer > div > button:first-of-type {
  display: none;
}

.quickmeeting-modal .ant-modal .ant-modal-footer > div > button:last-of-type {
  background-color: #1883ff;
  border-color: #1883ff;
  border-radius: 26px;
  width: 315px;
  height: 52px;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}

.quickmeeting-modal
  .ant-modal
  .ant-modal-footer
  > div
  > button:last-of-type[disabled] {
  background: #ccc;
  border-color: #cfcfcf;
  color: #a2a2a2;
}
