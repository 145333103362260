.modalconfirm {
}

.modalconfirm .ant-modal {
  width: 740px !important;
}

.modalconfirm .ant-modal .ant-modal-content {
  background: #000000;
  color: #ffffff;
}

.modalconfirm .ant-modal .ant-modal-header {
}

.modalconfirm .ant-modal .ant-modal-header .ant-modal-title {
}

.modalconfirm .ant-modal .ant-modal-header .ant-modal-title:after {
}

.modalconfirm .ant-modal .ant-modal-body {
}

.modalconfirm .ant-modal .ant-modal-body .logo-modal {
  display: flex;
  justify-content: center;
}

.modalconfirm .ant-modal .ant-modal-body .ico-modal {
  display: flex;
  justify-content: center;
  margin: 60px 0;
}

.modalconfirm .ant-modal .ant-modal-body .text-modal {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #ffffff;
}

.modalconfirm .ant-modal .ant-modal-body .sub-text-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
}

.modalconfirm .ant-modal .ant-modal-footer {
  padding: 60px 30px;
  border: none;
}

.modalconfirm .ant-modal .ant-modal-footer > div {
  display: flex;
  justify-content: center;
}

.modalconfirm .ant-modal .ant-modal-footer > div > button {
  border-radius: 10px;
  width: 315px;
  height: 50px;
  font-weight: 700;
  font-size: 14px;
}

.modalconfirm .ant-modal .ant-modal-footer > div > button:first-of-type {
  display: none;
}

.modalconfirm .ant-modal .ant-modal-footer > div > button:last-of-type {
  background-color: #1883ff;
  border-color: #1883ff;
  color: #fff;
}
