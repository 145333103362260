.DayPicker-Caption>div {
  font-family: 'Helvetica Neue LT Std';
  font-size: 18px;
  font-weight: 600;
}

.DayPicker-Day--today {
  background-color: #e7e7e7;
  color: #000000;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #1883ff;
  color: #ffffff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #1883ff;
  color: #ffffff;

}