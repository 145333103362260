#partner-signup-steps .yes-mobile {
    display: none
}

.btn-zindex {
    position: relative;
    z-index: 80;
    margin-bottom: 20px;
}

.cleaboth {
    clear: both;
}
@media screen and (max-width: 1088px) {
    .container {
        padding: 0 20px;
    }


    .navbar-menu {
        box-shadow: none;
    }

    .partner-step .container {
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
}

@media screen and (max-width: 768px) {

    .level-item, .level-item .field {
        width: 100%;
    }

    .bp-level h5 {
        margin-bottom: 20px;
    }

    .bp-level input {
        width: 100%!important;
    }

    .selectProject {
        margin-top: 80px;
    }

    .passo2 {
        padding-top: 200px!important;
    }

    .brm {
        display: none;
    }

    .heading-mobile {
        padding-top: 50px
    }

    /* .hero.is-fullheight {
        padding-bottom: 50px;
    } */

    .form-mobile .field {
        margin-bottom: 30px;
    }

    .form-mobile {
        padding-bottom: 50px;
    }

    .field .button.is-leaked {
        margin-left: 0!important;
    }

    .breadcrumb.is-medium {
        padding-top: 15px;
    }

    #partner-signup-steps .yes-mobile {
        display: block;
    }
    .client-step-1 {
        padding-top: 100px;
    }

    .np-basicFormInit .text {
        margin-bottom: 15px;
    }

    .fields-form-space {
        margin-top: 50px;
    }

    .media-template-card .media-left {
        display: none;
    }


    .card-content {
        padding: 20px;
    }

    body div.hero-body {
        padding: 0!important;
    }

    #root .uplift-step-2, #root .uplift-step-2 {
        padding-top: 60px !important;
    }

    .menu-list {
        margin-bottom: 50px;
    }

    .checkbox {
        margin-left: 0!important;
    }

    .radio-left {
        margin-left: 10px;
    }

    .field-cond {
        width: 100%;
        margin-bottom: 15px;
    }

    .text-field-margin {
        margin-top: 30px;
    }

    .mobile-small {
        width: 100%!important;
    }

    .mobile-steps #partner-signup-steps.reference-signup-steps {
        width: 100%;
        margin-left: 0;
    }

    #partner-signup-steps li a.progress-bar--menu-item {
        margin-left:0;
        height: 30px;
        position: relative;
        top: -10px;
    }

    #partner-signup-steps .is-active a.progress-bar--menu-item > span, #partner-signup-steps li a.progress-bar--menu-item > span {
        left: 0px!important;
        width: 100%;
        top: 27px!important;
    }

    .mob-button .field.is-narrow {
        overflow: hidden;
        margin-bottom: 40px;
    }

    .mob-button.no-margin-mob .field.is-narrow{
        margin-bottom: 0;
    }

    .mob-button .button, .btn-mobile {
        width: 100%;
    }

    .mob-centered {
        justify-content: inherit!important;
        display: block!important;
    }

    .mob-centered .is-expanded {
        width: 100%;
    }

    .upload-button-icon {
        float: right;
        position: relative;
        top: -5px;
    }



    body .container-calendar .sub-container-calendar  {
        margin-top: 40px;
    }


    body .container-calendar .schedule-container {
        padding: 0px;
        border: 0;
        height: 260px!important;
    }

    .container-calendar .icon i {
        font-size: 20px!important;
    }

    .bp-toolbar label {
        font-size: 20px!important;
    }

    .project-definition .schedule-title, .project-ongoing .schedule-title {
        font-size: 18px!important;
        font-weight: 500!important;
    }

    body .container-calendar .text-mob {
        margin: 20px 0 40px;
        font-size: 14px;
    }

    .resumo-proposta {
        margin-top: 40px;
    }

    .resumo-proposta .space-box {
        padding: 20px 20px 5px 20px;
    }

    .resumo-proposta .has-text-right {
        text-align: left!important;
    }

    .resumo-proposta .column {
        padding: 0 10px 15px;
    }

    .resumo-proposta .columns .tit {
        font-weight: bold;
    }

    .resumo-proposta .columns {
        margin-bottom: 30px;
        border-bottom: dotted 1px #999;
    }

    .big-idea-box div,.big-idea-box {
        width: 100% !important;
    }

    .client-step-1 {
        padding-top: 200px;
    }

    .for-np + .client-step > .container {
        padding: 20px!important;
    }

    .container-content {
        padding: 0!important;
    }

    .p-btns {
        padding: 0 20px!important;
    }

    .fileList {
        padding: 20px !important;
    }


    .uplift.partner-projects h1, .uplift.partner-projects h1 + p {
        padding: 0!important;
    }

    .uplift .invitation-tabs  {
        margin: 0;
    }

    .uplift .invitation-list-boxes {
            padding: 24px 0px;
    }

    .uplift .invitation-list-item-details {
        padding: 15px!important;
    }

    .uplift .box-with-cover .tag {
        top: -10px!important;
        left: -18px;
        right: inherit!important;
    }

    .status-mobile {
        padding: 0;
        position: relative;
        height: 30px;
    }

    .bt-add-mobile {
        margin-top: 30px;
    }

    .modal-content .table-main {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 700px) {
    .roxa th {
        min-width: 100px;
    }

    .roxa .th-item {
        min-width: 250px;
    }

    .media-template-card .subtitle,.media-template-card-content {
        padding: 20px!important;
    }

    .media-template-card .subtitle {
        font-size: 20px!important;
    }

    .project-ongoing .card-header-title {
        font-size: 16px!important;
    }

}

@media screen and (max-width: 380px) {
    .container {
        padding: 0;
    }

    .form-logoin {
        padding: 30px 20px!important;
    }
    .btn-full-w {
        display: block;
        float: inherit!important;
        margin-top: 40px;
        margin-bottom: 0px;
    }

    .btn-full-w .button.is-black.is-outlined {
        width: 100%;
    }
    .no-mobile {
        display: none;
    }
    .btns-calendario {
        display: block;
    }

    .btns-calendario button {
        width: 100%;
        margin-bottom: 10px;
    }

    .hero-head.is-fixed + div > .hero-body.for-np .title {
        font-size: 18px;
    }

    .step-4-header .btn-mobile {
        width: 100%!important;
    }
}
