.event-quotation-summary .tit {
    padding-left: 1rem;
    display: flex;
    align-items: center;
}

.event-quotation-summary .sum {
    padding-top: 1rem !important;
    padding-right: 1rem;
    text-align: right;
    font-weight: bold;
}

.event-quotation-summary .val {
    padding-right: 1rem;
    text-align: right;
}

.event-quotation-summary .edit {
    padding-right: 0.75rem;
    text-align: right;
}

.event-quotation-summary .column {
    border: solid 1px #f0f0f0;
}

.event-quotation-summary .subtit {
    padding-left: 1rem;
    display: flex;
    align-items: center;
}

.event-quotation-summary .subval {
    padding-right: 1rem;
    text-align: right;
}

.event-quotation-summary .head .column {
    background-color: #353535 !important;
    color: #ffffff;
    text-align: center;
}

.event-quotation-summary .spreadsheet .column {
    background-color: #dedede !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.event-quotation-summary .assets .column {
    background-color: #adadad !important;
    padding-top: 1rem;
}

.event-quotation-summary .items .column {
    background-color: #dedede !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.event-quotation-summary .items-sub .column {
    background-color: #dedede !important;
    padding-top: 1rem;
}

.event-quotation-summary .fee .column {
    background-color: #ababab !important;
    padding-top: 1rem;
}

.event-quotation-summary .tax .column {
    background-color: #ababab !important;
    padding-top: 1rem;
}

.event-quotation-summary .total .column {
    background-color: #434343 !important;
    padding-top: 1rem;
    color: #fff;
}

.event-quotation-summary .total .column strong {
    color: #fff;
}

