.quotation-asset-form table.main
{
    border-collapse: collapse;
    border: none !important;
}
.quotation-asset-form table.main td {
    background-color: #f2f2f2 !important;
    box-shadow: 0 0 1px #FFFFFF !important;
    vertical-align: middle;
}

.quotation-asset-form table th {
    background-color: #434343 !important;
    box-shadow: 0 0 1px #FFFFFF !important;
    color:#fff;
}

.quotation-asset-form table.main tfoot td {
    background-color: #434343 !important;
    color: #fff!important;
}

.quotation-asset-form table.main tfoot td strong span{
    color: #fff!important;
    font-weight: 600!important;
}

.quotation-asset-form table.list th
{
    padding: 1rem;
}

.quotation-asset-form table.list td
{
    border: solid 1px #efefef !important;
    vertical-align: middle;
}

.quotation-asset-form table.list td .checkmark
{
    border: solid 1px #eeeeee !important;
}

.quotation-asset-form .custom-option{
    cursor: pointer;
    padding: 0.5rem;
}

.quotation-asset-form .custom-option:hover{
    background-color: #fafafa;
}
