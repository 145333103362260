.quotation-item-summary table
{
    border-collapse: collapse;
    border: none !important;
    background-color: transparent;
}
.quotation-item-summary table td {
    background-color: #F2F2F2 !important;
    box-shadow: 0 0 1px #FFFFFF !important;
}

.quotation-item-summary table th {
    background-color: #434343 !important;
    box-shadow: 0 0 1px #FFFFFF !important;
    color: #fff!important;
}

.quotation-item-summary table tfoot td {
    background-color: #434343 !important;

}

.quotation-item-summary table tfoot td strong span {
    font-weight: 600;
    color: #fff;
}
