.bpitch-schedule .bpitch-schedule-topic {
    font-size: 80%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    margin-top: 0.25rem;
    border-radius: 0.25rem;
    background-color: #fafafa;
}

.bpitch-schedule-range .ant-input {
    border: none !important;
}

.bpitch-schedule-date .ant-input:read-only {
    border: none !important;
    border-bottom: solid 1px #000 !important;
    color: #363636;
    font-size: 1rem;
    text-align: center;
}

.bpitch-schedule-range .ant-calendar-range-picker-input{
    border-bottom: solid 1px #000 !important;
    padding: 1rem;
    background-color: white !important;
    color: #363636;
    font-size: 1rem;
}

.bpitch-schedule-date .ant-calendar-picker{
    border: none;
}

.bpitch-schedule-date .ant-calendar-picker-input{
    border-bottom: solid 1px #000 !important;

}

.bpitch-schedule-date .ant-calendar-picker:hover, .bpitch-schedule-date .ant-calendar-picker:focus {
    border: none;
}