.quotation-item-form table
{
    border-collapse: collapse;
    border: none !important;
}
.quotation-item-form table td {
    box-shadow: 0 0 1px #FFFFFF !important;
}

.quotation-item-form table th {
    background-color: #434343 !important;
    box-shadow: 0 0 1px #FFFFFF !important;
    color: #fff!important;
}

.quotation-item-form table tfoot td {
    background-color: #434343 !important;
}

.quotation-item-form table tfoot td strong span {
    font-weight: 600;
    color: #fff;
}
