.quotation-summary table
{
    border-collapse: collapse;
    border: none !important;
    width: 100%;
}

.quotation-summary table th {
    background-color: #353535 !important;
    color: #FFFFFF;
    padding: 1rem;
    font-size: 90%;
    text-align: center;
}

.quotation-summary table th:first-child {
    border-right: solid 1px #ffffff;
}

.quotation-summary table th:last-child {
    border-left: solid 1px #ffffff;
}

.quotation-summary table td:first-child {
    border-right: solid 1px #ffffff;
    text-align: right;
}

.quotation-summary table td:last-child {
    border-left: solid 1px #ffffff;
}

.quotation-summary table tr.asset-line td {
    background-color: #dedede !important;
    padding: 2rem;
}

.quotation-summary table tr.item-line td {
    background-color: #d1d1d1 !important;
    padding: 2rem;
    text-align: right;
}

.quotation-summary table tr.fee-line td {
    background-color: #dedede !important;
    padding: 2em;
    text-align: right;
}

.quotation-summary table tr.tax-line td {
    background-color: #d1d1d1 !important;
    padding: 2em;
    text-align: right;
}

.quotation-summary table tr.commission-line td {
    background-color: #7c7c7c !important;
    padding: 2em;
    text-align: right;
    color: #fff!important;
}

.quotation-summary table tr.invoice-line td {
    background-color: #434343 !important;
    padding: 2em;
    text-align: right;
    vertical-align: middle;
    color: #fff;
    font-weight: 600!important;
}

.quotation-summary table tr.invoice-line td span {
    font-weight: 600;
    font-size: 20px;
}

.quotation-summary table tr.invoice-line td:first-child {
     background-color: #eeeeee !important;
}



.quotation-summary table tfoot td {
    vertical-align: middle;
}
