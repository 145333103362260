table.billing-installments tbody td {
    padding: 1rem;
}

table.billing-installments tbody td.selected {
    padding: 2rem;
}

table.billing-installments tbody tr.selected {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}


table.billing-installments tbody tr.blurred {
    opacity: .2;
}

