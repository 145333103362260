.thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}

.thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
    background-color: #ffffff;
}

.thumb-inner {
    display: flex;
    min-width: 0;
    overflow: hidden;
}

.thumb-img {
    display: block;
    width: auto;
    height: 100%
}

.thumb-inner a {
    left: -20px;
    top: 10px;
    z-index: 99;
    background-color: #007aff;
}
