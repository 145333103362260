/*

CSS PROVISORIO PARA ESTILO DO END DESING

*/

.mainModalmsg {
  text-align: center;
  color: #fff;
  margin: 20px 0;
}

.mainModalmsg p {
  color: #fff;
  margin-bottom: 10px;
}

.alertTimezone {
  border: dotted 1px #d4d4d4;
  padding: 30px;
  margin-bottom: 30px;
  margin: 0 30px 30px;
}

.alertTimezone b {
  font-weight: bold;
}

.bp-layout {}

.errorSpace .ant-form-explain {
  margin-top: 10px !important;
}

.txtSpan {
  font-size: 11px !important;
}

.bp-video-preview {
  margin-bottom: 60px;
  max-width: 270px;
}

.bp-map-item h2 {
  margin-bottom: 50px;
  color: #1883ff;
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
}

.bp-map-item img.img-mapa {
  width: 90%;
}

.bp-map-item button img {
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
  left: -5px;
  top: -3px;
  transform: rotate(180deg);
}

.bp-video-faq {
  margin-bottom: 15px;
  padding-bottom: 17px;
  border-bottom: solid 1px #000;
}

.bp-video-faq h2 {
  position: relative;
  color: #434343;
  font-size: 15px;
  font-weight: 700;
  padding-left: 40px;
  cursor: pointer;
}

.bp-video-faq h2::before {
  position: absolute;
  content: "+";
  left: 15px;
  top: 0px;
  font-size: 20px;
  font-weight: 700;
  color: #1883ff;
}

.bp-video-faq.open h2::before {
  content: "-";
}

.bp-video-faq p {
  padding-left: 40px;
  color: #434343;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
}

.faq-description {
  height: 0;
  transition: all 0.5s ease;
  overflow: hidden;
}

.lineSearch {
  width: 100%;
  border-bottom: solid 1px #e2e2e2;
  margin: 50px 0;
}

.bp-video-faq.open .faq-description {
  height: auto;
}

.mainVideo {
  padding: 0 100px;
}

.bp-file-preview {
  margin-bottom: 5px;
}

.bp-file-preview a {
  border-bottom: solid 1px #d4d4d4;
  padding: 15px;
  display: block;
  text-align: left;
  color: #353535;
  transition: all 0.3s ease;
  opacity: 1;
}

.bp-file-preview a:hover {
  /* background: #fff; */
  color: #000;
  opacity: 0.5;
}

.paList span {
  margin-bottom: 5px !important;
}

.bp-video-preview span {
  color: #696969;
  font-size: 15px;
  position: relative;
}

.maskvideo {
  position: absolute;
  background: transparent;
  width: 100%;
  max-width: 300px;
  height: 80%;
  z-index: 90;
}

.bp-video-preview {
  cursor: pointer;
}

.bp-video-preview img {
  opacity: 1;
  transition: all 0.7s ease;
  width: 100%;
}

.bp-video-preview:hover img {
  opacity: 0.5;
}

.bp-tabsmanu .button {
  margin-right: 15px;
}

.bp-layout .ant-layout-sider {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.bp-layout .ant-menu {
  box-shadow: none;
  border: 0;
}

.bp-layout .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin: 0;
}

.bp-layout .ant-menu-inline>.ant-menu-item {
  height: 28px;
  line-height: 28px;
}

.bp-layout .ant-menu-inline>.ant-menu-item span {
  padding-left: 15px;
  font-size: 15px;
}

.section-menu {
  margin-bottom: 80px;
}

.loomDiv {
  position: relative;
  padding-bottom: 62.5%;
  height: 0;
}

.loomDiv iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pdfView {
  height: 100vh;
}

.btpull {
  overflow: hidden;
  margin-bottom: 20px;
}

.card-container.card-wikis {
  padding: 40px;
  margin-top: 30px;
}

.bp-layout .ant-menu-inline>.ant-menu-item.menu-all {
  padding: 0 40px 0;
  color: #1883ff;
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  margin-top: 40px;
}

.title-menu {
  color: #696969;
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 700;
  /* padding: 0 20px; */
  /* margin-bottom: 15px; */
}

.title-menu.tbottom {
  margin-top: 60px;
}

.menu-wikis .ant-menu-item>a {
  color: #696969;
}

.menu-wikis .ant-menu-item-selected>a {
  color: #1883ff;
}

.bp-headerpage {
  background: #fff;
  height: 74px;
  margin-top: 65px;
  box-sizing: border-box;
  padding: 19px 0;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.ant-menu-inline .ant-menu-item-selected::after {
  border-right: solid 3px #696969;
}

.menu-slider {
  padding: 20px;
  text-align: right;
  transition: all ease 0.3s;
}

.ant-layout-sider-collapsed .menu-slider {
  text-align: center;
}

.bp-layout .ant-menu {
  display: block;
  opacity: 1;
  transition: all 0.3s ease;
}

.ant-layout-sider-collapsed .ant-menu {
  display: none;
}

.iconDiv img {
  width: 55px;
}

.listBrandTable {
  overflow: hidden;
  border-bottom: solid 1px #efefef;
  padding: 5px;
}

.listBrandTable a {
  float: right;
}

.iconDiv.maior img {
  width: 75px;
}

.bp-group-radio .ant-radio-inner::after {
  top: 4px;
  left: 4px;
}

.bp-group-radio .vertical {
  height: 50px;
  line-height: 50px;
}

/* 
.bp-group-radio span {
  color: #000;
}
.bp-group-radio .ant-radio-inner {
  width: 25px;
  height: 25px;
}



.bp-group-radio .ant-radio-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}


.bp-group-radio .ant-radio-wrapper {
  flex: 1;
} */

.btsEditDelete {
  text-align: right;
}

.btsEditDelete a {
  margin: 10px;
}

.btsEditDelete a.button {
  margin: 0 0 5px 0 !important;
}

.form-tier {
  position: relative;
  top: -15px;
}

.btn-submit {
  clear: both;
  overflow: hidden;
}

.porcentBp input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.filterProject {
  text-align: right;
  margin-bottom: 20px;
}

.selectTier {
  width: 120px;
  display: inline-block;
  margin-right: 10px;
}

.iconeTxt {
  width: 25px;
  position: relative;
  top: -2px;
}

.ant-select-selection--multiple {
  min-height: 35px;
  top: -3px;
  position: relative;
}

.miniInfo {
  margin-top: 20px;
  line-height: 16px;
  font-size: 11px;
}

.SelectMoeda {
  width: 80px;
  float: right;
  margin: 20px 30px 0 0;
}

.descMini {
  margin-top: 0px;
}

.descMini p {
  font-size: 13px;
  margin-bottom: 10px !important;
  line-height: 10px !important;
  color: #4a4a4a !important;
  font-weight: normal;
}

.descMini p span {
  color: #4a4a4a !important;
  font-size: 11px !important;
}

.ant-card-head {
  font-weight: normal;
}

.SelectMin {
  font-size: 10px !important;
}

.titile-padding {
  padding: 30px 27px 0;
}

.some-bpool {
  text-align: right;
  padding: 20px;
}

.some-bpool.left {
  text-align: left;
}

.some-bpool .valor,
.some-bpool .porc {
  display: inline-block;
}

.some-bpool .valor {
  font-size: 16px;
  position: relative;
  top: -6px;
  margin-right: 8px;
}

.some-bpool .porc {
  font-weight: 600;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
}

.some-bpool .porc .vat,
.some-bpool .porc .value {
  display: block;
}

/*  LABEL  */

.ant-form-item-label>label,
.label-bp {
  font-size: 13px;
  font-weight: 700;
  line-height: 37px;
  color: #353535;
}

.ant-form label.ant-radio-wrapper {
  font-weight: normal !important;
}

.ant-form-item-label {
  margin-bottom: -7px;
}

.ant-select {
  display: block;
}

/*  INPUT  */

.ant-select-auto-complete.ant-select .ant-input {
  height: 35px;
}

.ant-input {
  height: 35px;
  border-radius: 20px !important;
  background: #e7e7e7;
  outline: none !important;
}

.has-error .ant-input,
.has-error .ant-input:hover {
  background: #e7e7e7;
}

/* RADIO */

.ant-radio {
  position: relative;
  top: 2px;
}

.has-error input.ant-input:read-only {
  border: solid 1px #f5222d !important;
}

input.ant-input:read-only {
  background: #e7e7e7 !important;
  border: solid 2px #e7e7e7 !important;
  width: 100%;
  padding: 15px 12px 12px;
  display: block;
  box-sizing: border-box;
  height: 35px;
  border-radius: 15px;
  font-family: "Helvetica Neue LT Std";
  color: #696969;
}

.ant-input-number {
  width: 100%;
  height: 35px;
  position: relative;
  top: -1px;
}

.ant-input-number-input,
.ant-calendar-picker-input {
  border: solid 1px #000;
  border-radius: 0;
  height: 35px;
}

.ant-radio-inner {
  width: 20px;
  height: 20px;
}

.ant-radio-inner::after {
  width: 10px;
  height: 10px;
}

/* 
.ant-radio-checked .ant-radio-inner {
  border-color: #000;
} */

.ant-form label.ant-radio-wrapper {
  color: #353535;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000;
  border-color: #000;
}

/* CHECKBOX */

.ant-checkbox-inner {
  border: solid 2px #000;
}

.ant-checkbox-inner::after {
  left: 18%;
}

.ant-input-number,
.ant-input-number:hover {
  border: 0 !important;
}

.ant-input-number-focused {
  box-shadow: none;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border: solid 2px #000;
}

.ant-checkbox-group .ant-checkbox-group-item {
  display: block;
  margin-bottom: 5px;
  color: #353535;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
}

.ant-drawer {
  z-index: 3147483000 !important;
}

.ant-select-dropdown {
  z-index: 3147483001 !important;
}

.ant-drawer-title {
  font-weight: 600;
}

.modaltypes {
  text-align: center;
}

.modaltypes h2 {
  margin-bottom: 40px;
}

.ant-modal-content {
  border-radius: 10px;
}

.pullcenter .control {
  text-align: center;
}

.customDots {
  border: solid 1px #1883ff;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  line-height: 33px;
  font-weight: bold;
  color: #333;
  font-size: 14px;
}

.slideAutor {
  font-size: 12px;
  font-style: italic;
  color: #565656;
}

.slideAutor span {
  text-transform: capitalize;
}

.slideNumber {
  padding: 0 0 55px;
  margin: 0 0 55px;
}

.slideNumber .slick-prev,
.slideNumber .slick-next {
  top: 23%;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.slideNumber .slick-dots li {
  width: 35px;
  height: 35px;
  margin: 0 2px;
}

.slideNumber .slick-active .customDots {
  color: #fff;
  background: #1883ff;
}

/* SEARCH - PLUS */

.ant-input-search-enter-button.InputPlus input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.InputPlus .ant-input-group .ant-input {
  width: 100%;
}

.InputPlus .ant-input-group-addon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.InputPlus .ant-input-group-addon button::after {
  content: "+";
  font-size: 30px;
  position: relative;
  color: #fff !important;
  top: -4px;
  left: 0px;
  font-weight: 400;
}

.InputPlus .ant-input-group>.ant-input:last-child,
.ant-input-group-addon:last-child {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.ant-input-search-enter-button.InputPlus+.ant-input-group-addon .ant-input-search-button,
.ant-input-search-enter-button.InputPlus input+.ant-input-group-addon .ant-input-search-button {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 0;
  display: block;
  background: #000;
}

/* SEARCH - Lupa */

.ant-input-search-enter-button.InputLupa input {
  border: solid 1px #000;
}

.InputLupa .ant-input-group-addon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.InputLupa .ant-input-group>.ant-input:last-child,
.ant-input-group-addon:last-child {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.ant-input-search-enter-button.InputLupa+.ant-input-group-addon .ant-input-search-button,
.ant-input-search-enter-button.InputLupa input+.ant-input-group-addon .ant-input-search-button {
  width: 35px;
  height: 35px;
  padding: 0;
  display: block;
  background: #000;
  border: 2px #000 solid;
}

/* TAGS */

.ant-select-selection--multiple .ant-select-selection__choice {
  background: #000;
  color: #fff;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #fff;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: #fff;
}

/*
    SELECT
*/
.ant-select-selection-selected-value {
  position: relative;
  top: 2px;
}

.ant-select-selection--single {
  height: 35px;
  border-radius: 25px;
  top: -1px;
  background: #e7e7e7;
  overflow: hidden;
}

.ant-select-arrow {
  height: 33px;
  width: 30px;
  background: transparent;
  color: #000;
  top: 0;
  right: 3px;
  margin: 0;
}

.ant-select-arrow i {
  font-size: 16px;
  top: 9px;
  position: relative;
}

/* ELEMTENTOS BP */

.ListDinamic {
  margin: 15px 0;
}

.ListDinamic li {
  border-bottom: dotted #ccc 1px;
  overflow: hidden;
}

.ListDinamic li a {
  color: #353535;
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  display: block;
  padding: 5px 10px;
  transition: background 0.7s ease;
  display: block;
  width: calc(100% - 50px);
  float: left;
  overflow: hidden;
  position: relative;
  height: 35px;
}

.ListDinamic li a span.txt {
  display: block;
  position: absolute;
  top: 4px;
  left: 33px;
}

.ListDinamic li a:hover {
  color: #ccc;
}

.ListDinamic li a i {
  margin-right: 10px;
}

.ListDinamic .iconList {
  position: relative;
  top: 7px;
  float: right;
  cursor: pointer;
}

/* BUTTON */

.ant-btn.ant-btn-primary {
  background: #000000;
  border-color: #000;
  color: #ffffff;
}

.ant-btn-primary.caps {
  text-transform: capitalize;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  background: #565656;
  border-color: #565656;
}

.ant-form-item-required::before {
  display: none;
}

.ant-upload.ant-upload-select-picture-card {
  position: relative;
}

.ant-checkbox-group {
  width: 100%;
}

@media (max-width: 768px) {
  .ant-radio-wrapper {
    white-space: normal;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #f7f7f7;
  }
}

.ant-form-item-label.space>label::after {
  display: none !important;
}

.ant-table-placeholder {
  border: solid 1px #000;
}

.ant-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.9);
}

/* .ant-modal-body {
    max-height: 520px;
    overflow: auto;
} */

.ant-select-dropdown .ant-empty-description {
  text-indent: -9999em !important;
  color: #fff;
  font-size: 0;
}

.ant-select-dropdown .ant-empty-description::before {
  content: "Sem Informação";
  font-size: 14px;
  display: block;
  text-indent: 0;
  color: #000;
}

.ant-upload.ant-upload-drag {
  height: 160px;
  z-index: 10;
}

.ant-upload.ant-upload-drag p.ant-upload-text {
  font-size: 10px;
  text-transform: uppercase;
  position: relative;
  line-height: 14px;
  z-index: 50;
  opacity: 1;
  transition: all 0.5s ease;
}

.ant-upload.ant-upload-drag p.ant-upload-text.carregando {
  opacity: 0.2;
  transition: all 0.5s ease;
}

.ant-upload.ant-upload-drag p.ant-upload-text span {
  display: block;
  margin: 5px 0;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 5px;
  position: relative;
  z-index: 50;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #000;
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: block;
}

#root .ant-table table {
  border: solid 1px #ccc;
  overflow: hidden;
}

.pb-bt .ant-upload.ant-upload-select.ant-upload-select-text {
  width: 100%;
}

.ant-btn-primary.ant-btn-block {
  width: 100%;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover .anticon-close,
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  color: #fff;
  font-weight: bold;
}

.card-container .ant-tabs-extra-content {
  display: none;
}

.ant-input-group-addon {
  background: #000;
  color: #fff;
  border-radius: 0;
  border: solid 1px #000;
}

.ant-calendar-picker-input.ant-input {
  padding: 0;
  background: #e7e7e7;
  overflow: hidden;
}

.ant-calendar-picker-input.ant-input input:read-only {
  background: transparent;
}

/* boxTitle */

.boxTitle {
  padding: 0 20px;
}

.boxTitle.left {
  padding-right: 40px;
}

.boxTitle.right {
  padding-left: 40px;
  border-left: solid 1px #000;
}

.checkInvite .checkbox .checkmark {
  top: 3px;
}

.tmargin {
  display: block;
  margin-bottom: 30px !important;
}

.admin-boxs .box {
  height: 100%;
}

.react-audio-player {
  height: 40px;
  width: calc(100% - 70px);
}

.titleAudio {
  margin-bottom: 10px;
}

.titleAudio span {
  float: left;
  width: 40px;
  height: 40px;
  background: #000;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: bold;
  padding: 15px 0;
  border-radius: 50%;
  margin-right: 15px;
}

.boxAudio {
  padding: 40px 20px;
  border-bottom: solid 1px #efefef;
}

.boxAudio .descricao {
  margin-top: 20px;
  font-size: 14px;
}

.rowFlexBp {
  margin-bottom: 130px;
}

.rowFlexBp .card {
  height: 100%;
}

.listFilesAudio {
  margin-top: 20px;
  padding-top: 20px;
  overflow: hidden;
  border-top: solid 1px #efefef;
}

.listFilesAudio a {
  float: left;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  font-size: 24px;
  text-align: center;
  box-sizing: border-box;
  padding-top: 17px;
  background: #efefef;
}

.totalTable {
  text-align: right;
  font-size: 20px;
  font-weight: bold;
}

.expTable {
  padding: 20px 50px 20px 0px;
}