.quotation-budget {
    font-size: 90%;
}

.quotation-budget .tit {
    padding-top: 0.75rem !important;
    padding-left: 1rem;
}

.quotation-budget .column {
    border: solid 1px #f0f0f0;
}

.quotation-budget .head .column {
    background-color: #434343 !important;
    color: #ffffff;
    text-align: center;
}

.quotation-budget .amount .column {
   background-color: #adadad !important;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.quotation-budget .bpcommission .column {
    background-color: #dedede !important;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.quotation-budget .working .column {
    background-color: #adadad !important;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.quotation-budget .total .column {
    background-color: #d1d1d1 !important;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.quotation-budget .balance .column {
    background-color: #434343 !important;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #fff;
}
